import React from 'react'
import Messages from '../components/messages'
import Tasks from '../components/tasks'
import Statistic from '../components/statistic'

const Dashboard = () => {
  return (
    <div className='px-2'>
      <div className=' rakete-font headline text-center fs-1 fw-semibold py-3'>DASHBOARD</div>
      <div className='row mx-2'>
        <div className='col-md-6'>
          <Messages />
        </div>
        <div className='col-md-6'>
          <Tasks />
        </div>
      </div>
        <div className='text-dark'>
          <Statistic id={'all'}/>
        </div>
    </div>
  )
}

export default Dashboard