import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { IMAGE_URL } from '../../App'; // Pfad anpassen falls nötig

// Hilfsfunktionen für Datumsformatierung
const formatGermanDateAndTime = (date) => {
    const d = dayjs(date);
    return d.isValid() ? d.locale('de').format('DD.MM.YYYY HH:mm') : 'Ungültiges Datum';
};

function TournamentInfoBox({ tournament, participants, participantsConfirmed, participantsWait }) {

    const getModusText = (modus) => {
        switch (modus) {
            case 0: return "Liga";
            case 1: return "KO-Runde";
            case 2: return "Liga + KO- Runde";
            default: return "-";
        }
    };

    if (!tournament) {
        return null;
    }

    return (
        <>
            {/* Titel bleibt oben */}
            <div className='text-center rakete-font fs-5 my-3 text-uppercase fw-semibold'>Infos</div>

            {/* Bild bleibt oben */}
            {tournament.picture && (
                 <div className='my-2 text-center'>
                    <img
                        className="tournament-thumb rounded shadow-sm"
                        src={`${IMAGE_URL}/uploads/${tournament.picture}`}
                        alt={`${tournament.name || 'Turnierbild'}`}
                        onError={(e) => { e.target.onerror = null; e.target.src=`https://placehold.co/300x200/e1e1e1/909090?text=Bild+fehlt` }}
                    />
                </div>
            )}

            {/* Ort und Datum wieder normal dargestellt */}
            <div className="text-center rakete-font">{tournament.location || '-'}</div>
            <div className="text-center rakete-font mb-3">{formatGermanDateAndTime(tournament.date)} Uhr</div> {/* mb-3 für Abstand zur Box */}


            {/* Container für die Tabellen mit Rand, abgerundeten Ecken und hellgrauem Hintergrund */}
            <div className='border  rounded shadow-sm p-3 my-3'>
                {/* Row für die Spalten der Tabellen */}
                <div className='row'>
                    {/* Regeln Tabelle in Spalte */}
                    <div className='col-md-6'>
                        {/* Tabelle ohne extra Hintergrund auf Zeilen */}
                        <table className="table mb-0 table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Regeln</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Kein bg-light auf tr */}
                                <tr>
                                    <td className='rakete-font fs-9'>Teams</td>
                                    <td className="fs-6 text-end">{tournament.teams ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td className='rakete-font fs-9'>Spieleranzahl</td>
                                    <td className='fs-6 text-end'>{tournament.players ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td className='rakete-font fs-9'>Teilnahmegebühr</td>
                                    <td className='fs-6 text-end'>{tournament.prize ?? 0}€</td>
                                </tr>
                                <tr>
                                    <td className='rakete-font fs-9'>Modus</td>
                                    <td className='fs-6 text-end'>{getModusText(tournament.modus)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Teams Tabelle in Spalte */}
                    <div className='col-md-6'>
                         {/* Tabelle ohne extra Hintergrund auf Zeilen */}
                        <table className="table mb-0 table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Teams</th>
                                </tr>
                            </thead>
                            <tbody>
                                 {/* Kein bg-light auf tr */}
                                <tr>
                                    <td className='rakete-font fs-9'>Anmeldungen</td>
                                    <td className="fs-6 text-end">{participants?.length ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className='rakete-font fs-9'>Zugelassen</td>
                                    <td className='fs-6 text-end'>{participantsConfirmed?.length ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className='rakete-font fs-9'>Warteliste</td>
                                    <td className='fs-6 text-end'>{participantsWait?.length ?? 0}</td>
                                </tr>
                                 {/* Leere Zeile für gleiche Höhe */}
                                 <tr>
                                     <td>&nbsp;</td>
                                     <td></td>
                                 </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Infotext wieder normal dargestellt (nur wenn Turnier nicht abgeschlossen) */}
            {!tournament.completed && (
                <>
                    <div className='text-uppercase fw-semibold rakete-font text-center mt-3'>Infotext</div> {/* mt-3 für Abstand */}
                    <div
                        className='mx-3 mt-2 mb-4' // Angepasste Margins
                        dangerouslySetInnerHTML={{ __html: typeof tournament.info === 'string' ? tournament.info : 'Kein Infotext vorhanden.' }}
                    />
                </>
            )}
        </>
    );
}

export default TournamentInfoBox;

