import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import FormRange from 'react-bootstrap/esm/FormRange';
import { IMAGE_URL } from '../../App.js';


function TournamentEdit({ show, onHide, tournament }) {
    const [name, setName] = useState("");
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [modus, setModus] = useState('');
    const [picture, setPicture] = useState('');
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState("");
    const [preview, setPreview] = useState();
    const [info, setInfo] = useState("");
    const [prize, setPrize] = useState();
    const [players, setPlayers] = useState();

    useEffect(() => {
        if (tournament) {
            setName(tournament.name);
            setDate(new Date(tournament.date));
            setLocation(tournament.location);
            setModus(tournament.modus);
            setTeams(tournament.teams);
            setPicture(tournament.picture);
            setPreview(`${IMAGE_URL}/uploads/${tournament.picture}`);
            setInfo(tournament.info);
            setPrize(tournament.prize);
            setPlayers(tournament.players);
        }
    }, [tournament]);

    function handleChange(event) {
        setPicture(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
    }

    const closeTournamentEdit = () => {
        setPreview();
        onHide();
    }

    const handleEditTournament = () => {

        const formData = new FormData();
        formData.append("name", name)
        formData.append('date', date);
        formData.append('location', location);
        formData.append("modus", modus);
        if(picture != tournament.picture) {
            formData.append('picture', picture);
        }
        formData.append("teams", teams);
        formData.append("info", info);
        formData.append("prize", prize);
        formData.append("players", players);
        setLoading(true);

        axios
            .put(`api/Tournaments/${tournament._id}`, formData)
            .then(() => {
                setLoading(false);
                setPreview();
                onHide();
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeTournamentEdit} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeTournamentEdit}>Turnier erstellen</Modal.Header>
                <Modal.Body className='fs-7'>
                    <img src={preview} className="img-fluid img-preview" />
                    <Form.Group>
                        <Form.Label className="rakete-font text-uppercase fs-8">Bild</Form.Label>
                        <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label value="name" className="rakete-font text-uppercase fs-8 mt-3">turniername</Form.Label>
                        <Form.Control
                            id="tournament"
                            type="text"
                            className='form-control-sm'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group >
                    <Form.Group className='my-4'>
                        <Form.Label className="rakete-font text-uppercase fs-8 me-3 mt-4">Datum / Zeit</Form.Label>
                        <DateTimePicker
                            defaultValue={dayjs(date)}
                            selected={date}
                            onChange={(newDate) => setDate(newDate)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label value="Location" className="rakete-font text-uppercase fs-8" >Ort</Form.Label>
                        <Form.Control
                            id="location"
                            type="text"
                            className='form-control-sm'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group className="my-2" as={Col}>
                            <Form.Label value="players" className="rakete-font text-uppercase fs-8">Spieler pro Team</Form.Label>
                            <Form.Control
                                id="players"
                                type="number"
                                className='form-control-sm'
                                value={players}
                                onChange={(e) => setPlayers(e.target.value)}
                            />

                        </Form.Group>
                        <Form.Group className="my-2 role" as={Col}>
                            <Form.Label className="rakete-font text-uppercase fs-8">Anzahl Teams</Form.Label>
                            <Form.Control
                                id="teams"
                                size="sm"
                                type="number"
                                className='form-control-sm'
                                value={teams}
                                onChange={(e) => setTeams(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group className="my-2 role" as={Col}>
                        <Form.Label value="Modus" >Modus</Form.Label>
                        <Form.Select
                            size="sm"
                            type="text"
                            className='form-control-sm'
                            value={modus}
                            onChange={(e) => setModus(e.target.value)}
                        >
                            <option value="0">Liga</option>
                            <option value="1">KO-Runde</option>
                            <option value="2">Liga + KO-Runde</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="my-2 role" as={Col}>
                        <Form.Label className="rakete-font text-uppercase fs-8">Teilnahmegebühr</Form.Label>
                        <Form.Control
                            id="prize"
                            size="sm"
                            type="number"
                            className='form-control-sm'
                            value={prize}
                            onChange={(e) => setPrize(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label value="info" className="rakete-font text-uppercase fs-8">Info</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            id="tournament"
                            type="text"
                            className='form-control-sm'
                            value={info}
                            onChange={(e) => setInfo(e.target.value)}
                        />
                    </Form.Group >
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleEditTournament} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeTournamentEdit}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default TournamentEdit;