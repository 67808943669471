import React, { useState } from 'react';
import axios from 'axios';

function MemberDelete({ show, id , onHide, indicator}) {
  const [loading, setLoading] = useState(false);

  const handleMemberAccount = () => {
    setLoading(true);
    axios
      .put(`/members/account/${id}`)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('Error deleting Member:', error);
      });
  };

  if (!show) {
    return null
  } else {
    return (
      <div className='mt-4 mx-2 text-center fs-7'>
        {indicator === true ? "Bist du dir sicher, dass du den Useraccount deaktivieren willst?" : "Möchtest du einen Account für diesen Benutzer anlegen?"}
        
        <div className='pt-3 div-justify-middle'>
          <button onClick={handleMemberAccount} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" disabled={loading}>{indicator === true ? "Deaktivieren" : "Anlegen"}</button>
        </div>
      </div>
    );
  }
}
export default MemberDelete;