import React, { useState } from 'react';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function MemberExit({ show, member }) {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();

  const handleMemberExit = () => {

    const formData = {
      exit: date
    }

    setLoading(true);
    axios
      .put(`/members/${member._id}`, formData)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch(error => {
        setLoading(false);
        console.error('Fehler beim abmelden:', error);
      });
  };

  if (!show) {
    return null
  } else {
    return (
      <div className='mt-2 mx-2 text-center'>
        {!member.exit ? 
        <div>
        <DatePicker label="Austrittsdatum" className='my-3' value={date} onChange={(newDate) => setDate(newDate)} />
        <div>
          <button onClick={handleMemberExit} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" disabled={loading}>Bestätigen</button>
        </div>
      </div> :
        <div>
            <div className=' my-3 fw-semibold'>
              Austritt zum {dayjs(member.exit).format("DD.MM.YYYY")}
            </div>
            <div>
              <button onClick={handleMemberExit} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" disabled={loading}>Zurücksetzen</button>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default MemberExit;