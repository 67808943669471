import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from "react-toastify";
import { FiCheckCircle, FiXCircle } from "react-icons/fi"; // Icons importieren

function ConfirmModal({ show, onHide, tournament, participant, topic}) {
  const [loading, setLoading] = useState(false);
  const [sendMail, setSendMail] = useState(true);
  const [localTopic, setLocalTopic] = useState(null);

  const isInternal = participant?.intern === true;

  useEffect(() => {
    if (show) {
      setLocalTopic(topic);
      if (isInternal) {
        setSendMail(false);
      } else {
        setSendMail(true);
      }
    }
  }, [show, topic, participant, isInternal]);

  // Mapping mit ursprünglichen Texten wiederhergestellt
  const templateMapping = {
    1: { title: 'Zahlungseingang bestätigen', text: `Möchtest du den Zahlungseingang für ${participant?.teamname || 'das Team'} wirklich bestätigen?`, template: '67c6d7d4e33d3353672888ff', formData: { paid: true } },
    2: { title: 'Rückerstattung bestätigen', text: `Möchtest du die Rückerstattung für ${participant?.teamname || 'das Team'} wirklich bestätigen?`, template: '67c6d7dfe33d335367288902', formData: { paid: false } },
    3: { title: 'Teilnahme bestätigen', text: `Möchtest du ${participant?.teamname || 'das Team'} als Teilnehmer hinzufügen?`, template: '67c6d7ede33d335367288905', formData: { status: 1 } }, // Text vereinfacht
    4: { title: 'Warteliste bestätigen', text: 'Möchtest du diesen Teilnehmer wirklich auf die Warteliste setzen?', template: '67c6d7f8e33d335367288908', formData: { status: 2 } },
    5: { title: 'Abmeldung bestätigen', text: 'Möchtest du die Abmeldung wirklich durchführen?', template: '67c6d80be33d33536728890b', formData: { status: 3 } }
  };

  const { title, text, template, formData } = templateMapping[localTopic] || {
    title: 'Aktion bestätigen',
    text: 'Möchtest du diese Aktion wirklich durchführen?',
    template: null,
    formData: {}
  };

  const sendConfirmationEmail = async () => {
    if (!template || !participant?._id || !tournament) {
         console.warn("Template-ID, Teilnehmer-ID oder Turnier-ID fehlt für E-Mail-Versand.");
         return;
     }
    try {
      const emailData = { emailTemplateId: template };
      const response = await axios.post(`/mail/send-email/${tournament}/${participant._id}`, emailData);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Bestätigungs-E-Mail erfolgreich gesendet!");
      } else {
        throw new Error(response.data?.message || `E-Mail-Server antwortete mit Status ${response.status}`);
      }
    } catch (error) {
      console.error("Fehler beim Versenden der Bestätigungs-E-Mail:", error);
      toast.warn("Aktion erfolgreich, aber Bestätigungs-E-Mail konnte nicht gesendet werden.");
    }
  };

  const handleConfirm = async () => {
    if (!participant?._id || !tournament || !formData) {
        toast.error("Fehler: Teilnehmer- oder Turnierdaten fehlen.");
        return;
    }

    setLoading(true);
    try {
      await axios.put(`/tournament/${tournament}/participants/${participant._id}`, formData);
      toast.success("Aktion erfolgreich durchgeführt!");

      if (sendMail && !isInternal) {
        await sendConfirmationEmail();
      }

      onHide(true);

    } catch (error) {
      console.error("Fehler bei der Aktion:", error);
      toast.error(error.response?.data?.message || "Aktion fehlgeschlagen.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="md" show={show} onHide={() => onHide(false)} centered className='no-carot'>
      <Modal.Header className='justify-content-between border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mx-2'>
          <div className='fw-semibold text-center fs-6'>{text}</div>
        </div>
        {template && (
            <Form.Group className="mx-4 my-4">
              <Form.Check
                type="checkbox"
                id={`sendMailCheck-${participant?._id}`}
                // Ursprüngliches Label wiederhergestellt
                label="E-Mail senden"
                className="mt-2"
                checked={sendMail}
                disabled={isInternal || loading}
                onChange={(e) => setSendMail(e.target.checked)}
                title={isInternal ? "Keine E-Mails an interne Teams" : ""}
              />
            </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0">
            <button
                className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                type="button"
                onClick={() => onHide(false)}
                disabled={loading}
            >
                 <FiXCircle className="me-1"/> Schließen
            </button>
            <button
                className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                type="button"
                onClick={handleConfirm}
                disabled={loading}
            >
                {/* Ursprünglicher Lade-Text wiederhergestellt */}
                {loading ? "Verarbeiten..." : <><FiCheckCircle className="me-1"/> Bestätigen</>}
            </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
