import React from 'react'
import Messages from '../components/messages'
import Tasks from '../components/tasks'
import Statistics from '../components/statistics'
import News from './News'
import History from '../components/history/History'

function Website() {
  return (
    <>
    <div className='pb-4'><News /></div>
    <div className='pb-2'><History /></div>
    <div className='fw-semibold rakete-font headline headline fs-1 mt-3 text-center'>STATISTIK</div>
    <div className=' rakete-font fs-4 fw-semibold py-3 ms-3 text-center text-danger rounded-4 shadow-lg mx-3 my-3 bg-white'>
        <div>
          <Statistics/>
        </div>
        </div>
    </>
  )
}

export default Website