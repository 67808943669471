import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
// Button von react-bootstrap wird nicht mehr für Footer benötigt
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
// FaXmark, FaCheck durch Fi-Icons ersetzt für Konsistenz
import { FaRegTrashCan, FaArrowLeft } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import { TbEditCircle } from "react-icons/tb";
import { FiSave, FiXCircle, FiCheck, FiX } from "react-icons/fi"; // Icons für Buttons hinzugefügt
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EmailTemplates({ show, onHide }) {
  const [loading, setLoading] = useState(false); // Loading state hinzugefügt für Konsistenz
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState(null);
  const [editTemplate, setEditTemplate] = useState(null);
  const quillEditRef = useRef(null);

  const placeholderMap = [
      { placeholder: '{{firstname}}', alias: 'Vorname' },
      { placeholder: '{{lastname}}', alias: 'Nachname' },
      { placeholder: '{{teamname}}', alias: 'Teamname' },
      { placeholder: '{{email}}', alias: 'E-Mail' },
      { placeholder: '{{email1}}', alias: 'E-Mail 2' },
      { placeholder: '{{birthday}}', alias: 'Geburtstag' },
      { placeholder: '{{street}}', alias: 'Straße' },
      { placeholder: '{{houseNumber}}', alias: 'Hausnr.' },
      { placeholder: '{{postalCode}}', alias: 'PLZ' },
      { placeholder: '{{city}}', alias: 'Stadt' },
      { placeholder: '{{phoneNumber}}', alias: 'Telefon' },
      { placeholder: '{{paid}}', alias: 'Bezahlstatus' },
      { placeholder: '{{tournament_name}}', alias: 'Turniername' },
      { placeholder: '{{tournament_location}}', alias: 'Ort' },
      { placeholder: '{{tournament_date}}', alias: 'Datum' },
      { placeholder: '{{tournament_time}}', alias: 'Zeit' },
      { placeholder: '{{tournament_prize}}', alias: 'Gebühr' },
      { placeholder: '{{tournament_info}}', alias: 'Turnierinfo' },
      { placeholder: '{{tournament_signup}}', alias: 'Anmelde-Button' },
  ];

  const fetchEmailTemplates = async () => {
    setLoading(true); // Loading anzeigen
    try {
      const response = await axios.get(`/api/EmailTemplate`);
      setEmailTemplates(response.data.data || []);
    } catch (error) {
      console.error("Fehler beim Laden der Templates:", error);
      toast.error("Fehler beim Laden der Email-Templates");
      setEmailTemplates([]);
    } finally {
      setLoading(false); // Loading beenden
    }
  };

  const handleChangeQuillInEdit = (value) => {
    if (editTemplate) {
      setEditTemplate(prev => ({ ...prev, content: value }));
    }
  };
   const handleChangeQuillInNew = (value) => {
    if (newTemplate) {
      setNewTemplate(prev => ({ ...prev, content: value }));
    }
  };

  useEffect(() => {
    if (show) {
      fetchEmailTemplates();
    } else {
        setNewTemplate(null);
        setEditTemplate(null);
    }
  }, [show]);

  const handleAddNewRow = () => {
    if (!newTemplate) {
      setNewTemplate({ title: '', subject: '', content: '', deletable: true });
      setEditTemplate(null);
    }
  };

  const handleInputChange = (field, value) => {
    if (newTemplate) {
      setNewTemplate({ ...newTemplate, [field]: value });
    } else if (editTemplate) {
      setEditTemplate({ ...editTemplate, [field]: value });
    }
  };

  const handleSaveNewTemplate = async () => {
    if (!newTemplate || !newTemplate.title) {
      toast.error("Titel ist erforderlich");
      return;
    }
    setLoading(true); // Loading starten
    try {
      const payload = {
          title: newTemplate.title,
          subject: newTemplate.subject || '',
          content: newTemplate.content || '',
          deletable: newTemplate.deletable === undefined ? true : newTemplate.deletable,
      };
      await axios.post(`/api/EmailTemplate`, payload);
      setNewTemplate(null);
      await fetchEmailTemplates(); // Lade Liste neu (setzt loading automatisch)
      toast.success("Email-Vorlage erfolgreich gespeichert");
    } catch (error) {
      console.error("Fehler beim Speichern:", error);
      toast.error("Fehler beim Speichern der Email-Vorlage");
      setLoading(false); // Loading im Fehlerfall beenden
    }
    // setLoading wird durch fetchEmailTemplates im Erfolgsfall beendet
  };

  const handleDeleteTemplate = async (id) => {
    // Füge Bestätigungsdialog hinzu
     if (!window.confirm("Möchtest du diese Vorlage wirklich löschen?")) {
         return;
     }
     setLoading(true); // Loading starten
    try {
      await axios.delete(`/api/EmailTemplate/${id}`);
      await fetchEmailTemplates(); // Lade Liste neu (setzt loading automatisch)
      toast.success("Email-Vorlage erfolgreich gelöscht");
    } catch (error) {
      console.error("Fehler beim Löschen:", error);
      toast.error("Fehler beim Löschen der Email-Vorlage");
      setLoading(false); // Loading im Fehlerfall beenden
    }
  };

  const handleEditTemplate = (template) => {
    setEditTemplate({ ...template });
    setNewTemplate(null);
  };

  const handleSaveEditedTemplate = async () => {
     if (!editTemplate) return;
     setLoading(true); // Loading starten
     try {
         const { _id, deletable, ...updateData } = editTemplate;
         await axios.put(`/api/EmailTemplate/${_id}`, updateData);
         setEditTemplate(null); // Zurück zur Übersicht
         await fetchEmailTemplates(); // Lade Liste neu (setzt loading automatisch)
         toast.success("Email-Vorlage erfolgreich aktualisiert");
     } catch (error) {
         console.error("Fehler beim Aktualisieren:", error);
         toast.error("Fehler beim Aktualisieren der Email-Vorlage");
         setLoading(false); // Loading im Fehlerfall beenden
     }
  };

  const handleBackToOverview = () => {
    setEditTemplate(null);
  };

  const handleClose = () => {
    setNewTemplate(null);
    setEditTemplate(null);
    onHide();
  };

  const insertPlaceholder = (placeholder) => {
    const quill = quillEditRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertText(range.index, placeholder, 'user');
        quill.setSelection(range.index + placeholder.length, 0, 'user');
      } else {
        const length = quill.getLength();
        quill.insertText(length, placeholder, 'user');
        quill.setSelection(length + placeholder.length, 0, 'user');
      }
      quill.focus();
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered className='no-carot'>
      <Modal.Header className='justify-content-between border border-0 rakete-rot' closeButton>
        {editTemplate ? (
          <>
            <FaArrowLeft size={20} className='hover icon-edit me-2' onClick={handleBackToOverview} title="Zurück zur Übersicht" style={{ cursor: 'pointer' }}/>
            <Modal.Title className='rakete-font fw-semibold text-uppercase'>{editTemplate.title}</Modal.Title>
             <div style={{ width: '20px' }}></div>
          </>
        ) : (
          <Modal.Title className='rakete-font fw-semibold text-uppercase'>Email - Vorlagen</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className='shadow'>
        <div className='mx-2'>
          {!editTemplate}
          <div className='fw-semibold text-center fs-6'>
            <div className="text-center">
              {!editTemplate ? (
                <>
                  <div className='row py-2 d-flex border-top border-start border-end rounded-top-4 align-items-center justify-content-center'>
                    <div className='col fs-7 text-start text-wrap fw-semibold'>Name</div>
                  </div>
                  {loading && !newTemplate && emailTemplates.length === 0 ? ( // Zeige nur initialen Ladezustand
                    <div className='row py-3 d-flex bg-light text-secondary border-top border-start border-end align-items-center justify-content-center'> Lade Templates... </div>
                  ) : emailTemplates.length === 0 && !newTemplate ? (
                    <div className='row py-3 d-flex bg-light text-secondary border-top border-start border-end align-items-center justify-content-center'> Keine Email-Vorlagen vorhanden </div>
                  ) : (
                    emailTemplates.map((template) => (
                      template ? (
                        <div key={template._id} className={`row py-3 d-flex border-top border-start border-end align-items-center justify-content-center ${!template.deletable && 'bg-light'}`}>
                          <div className='col-8 fs-7 text-start text-wrap text-secondary'> {template.title || 'Unbekannt'} </div>
                          <div className='col-4 text-end'>
                            {template.deletable && (
                              <FaRegTrashCan size={20} onClick={() => handleDeleteTemplate(template._id)} title="Email-Vorlage löschen" className='hover icon-edit me-4' style={{ cursor: 'pointer' }} disabled={loading}/>
                            )}
                            <TbEditCircle size={22} onClick={() => handleEditTemplate(template)} title="Email-Vorlage bearbeiten" className='hover icon-edit text-end' style={{ cursor: 'pointer' }} disabled={loading}/>
                          </div>
                        </div>
                      ) : null
                    ))
                  )}
                  {/* Neue Template Eingabezeile */}
                  {newTemplate && (
                    <div className="row py-3 d-flex bg-light border-top border-start border-end align-items-center justify-content-center">
                      <div className='col-8 fs-7 text-start text-wrap text-secondary'>
                        <input type="text" value={newTemplate.title} onChange={(e) => handleInputChange('title', e.target.value)} placeholder="Template - Name" className="form-control form-control-sm"/>
                      </div>
                      <div className='col-4 text-end'>
                        <FiX size={20} className='hover icon-edit me-3' onClick={() => setNewTemplate(null)} title="Eingabe abbrechen" style={{ cursor: 'pointer' }}/>
                        <FiCheck size={20} className='hover icon-edit' onClick={handleSaveNewTemplate} title="Template speichern" style={{ cursor: 'pointer' }}/>
                      </div>
                    </div>
                  )}
                  {/* Button zum Hinzufügen */}
                  {!newTemplate && (
                      <div className='row py-2 d-flex border rounded-bottom-4 hover add align-items-center justify-content-center' onClick={handleAddNewRow} style={{ cursor: 'pointer' }}>
                        <HiOutlinePlus size={22} className='' title="Email-Vorlage hinzufügen" />
                      </div>
                  )}
                </>
              ) : (
                 // --- BEARBEITUNGSMODUS ---
                 <div className="text-start">
                   {/* ... (Header for Edit mode) ... */}
                   <div className="row py-3 d-flex bg-light border-top border-start cursor border-end align-items-center justify-content-center">
                     <div className='col-12 fs-7 text-start text-wrap text-secondary'>
                       <label className="form-label fw-semibold">Betreff</label>
                       <input
                         type="text" value={editTemplate.subject || ''}
                         onChange={(e) => handleInputChange('subject', e.target.value)}
                         placeholder="Betreff" className="form-control form-control-sm mb-3"
                       />
                       <label className="form-label fw-semibold">Inhalt</label>
                       <div className='bg-white'>
                         <ReactQuill
                           ref={quillEditRef}
                           value={editTemplate.content || ''}
                           onChange={handleChangeQuillInEdit}
                           modules={{ toolbar: [ /* ... toolbar options ... */ ] }}
                           placeholder="Schreibe hier..."
                         />
                       </div>
                       {/* Platzhalter-Buttons */}
                       <div className="mt-2 pt-2 border-top d-flex flex-wrap justify-content-start align-items-center">
                           <span className="me-2 small text-muted">Einfügen:</span>
                           {placeholderMap.map(({ placeholder, alias }) => (
                               <button
                                   key={placeholder} type="button"
                                   className="btn btn-outline-secondary btn-sm m-1 py-0 px-2"
                                   onClick={() => insertPlaceholder(placeholder)}
                                   title={`Platzhalter ${placeholder} einfügen`}
                               >
                                   {alias}
                               </button>
                           ))}
                       </div>
                     </div>
                   </div>
                   <div className='row py-0 d-flex border rounded-bottom-4'></div>
                 </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* Angepasster Footer */}
      <Modal.Footer className="border-0">
        {editTemplate ? (
          // Buttons im Edit-Modus
          <>
            <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" type="button" onClick={handleBackToOverview} disabled={loading}>
                 <FiXCircle className="me-1"/> Abbrechen
            </button>
            <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" type="button" onClick={handleSaveEditedTemplate} disabled={loading}>
                 {loading ? 'SPEICHERT...' : <><FiSave className="me-1"/> Speichern</>}
            </button>
          </>
        ) : (
          // Button im Übersichts-Modus
          <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" type="button" onClick={handleClose} disabled={loading}>
               <FiXCircle className="me-1"/> Schließen
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EmailTemplates;
