import React from 'react';

function TournamentRanking({ ranking }) {
    // Zeige nichts, wenn kein Ranking vorhanden oder leer
    if (!ranking || ranking.length === 0) {
        return null;
    }

    return (
        <>
            <div className='text-uppercase fw-semibold rakete-font text-center text-secondary fs-4 mt-3'>Platzierung</div>
            <div className='mx-3 mt-3 mb-4'>
                <div className="row justify-content-center align-items-end my-4">
                    {/* Zweiter Platz */}
                    {ranking.length >= 2 && (
                        <div className="col-4 text-center">
                            <div className="bg-secondary text-white p-3 rounded-top">
                                <div className="fw-semibold rakete-font text-uppercase fs-6">{ranking[1]}</div>
                            </div>
                            <div className="bg-light text-dark rakete-rot shadow justify-content-center align-items-center d-flex fs-3 rakete-font fw-semibold" style={{ height: '75px' }}>2.</div>
                        </div>
                    )}
                    {/* Erster Platz */}
                    {ranking.length >= 1 && (
                        <div className="col-4 text-center">
                            <div className="podium podium-first bg-warning text-white p-3 rounded-top">
                                <div className="fw-semibold rakete-font text-uppercase fs-6">{ranking[0]}</div>
                            </div>
                            <div className="bg-light text-dark rakete-rot shadow justify-content-center align-items-center d-flex fs-3 rakete-font fw-semibold" style={{ height: '100px' }}>1.</div>
                        </div>
                    )}
                    {/* Dritter Platz */}
                    {ranking.length >= 3 && (
                        <div className="col-4 text-center">
                            <div className="bg-bronze text-white p-3 rounded-top" style={{ backgroundColor: '#cd7f32' }}>
                                <div className="fw-semibold rakete-font text-uppercase fs-6">{ranking[2]}</div>
                            </div>
                            <div className="bg-light text-dark rakete-rot shadow justify-content-center align-items-center d-flex fs-3 rakete-font fw-semibold" style={{ height: '50px' }}>3.</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default TournamentRanking;
