import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Chart.js Registrierung
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VisitorBarChart = () => {
  const [visitorData, setVisitorData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const currentDate = new Date();
    const currentYear = currentDate.getFullYear(); // Holen des aktuellen Jahres
    const currentMonth = currentDate.getMonth() + 1; // Holen des aktuellen Monats (1-basiert)
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Abrufen aller Besuchsdaten
    axios.get('/visitors')
      .then(response => {
        setVisitorData(response.data);
        setFilteredData(response.data); // Initial alle Daten anzeigen
        extractYearsAndMonths(response.data); // Automatisch Jahre und Monate extrahieren
        setLoading(false);
      })
      .catch(error => {
        setError('Fehler beim Laden der Besucherdaten');
        setLoading(false);
      });
  }, []);

  const extractYearsAndMonths = (data) => {
    const uniqueYears = new Set();
    const uniqueMonths = new Set();

    data.forEach(item => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Monat ist 0-basiert, daher +1

      uniqueYears.add(year);
      uniqueMonths.add(month);
    });

    setYears(Array.from(uniqueYears).sort()); // Jahre sortiert hinzufügen
    setMonths(Array.from(uniqueMonths).sort((a, b) => a - b)); // Monate sortiert hinzufügen
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    filterData(event.target.value, month);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    filterData(year, event.target.value);
  };

  // Daten filtern nach Jahr und Monat
  const filterData = (selectedYear, selectedMonth) => {
    let filtered = visitorData;

    if (selectedYear !== 'all') {
      filtered = filtered.filter(item => new Date(item.date).getFullYear() === parseInt(selectedYear));
    }

    if (selectedMonth !== 'all') {
      filtered = filtered.filter(item => new Date(item.date).getMonth() + 1 === parseInt(selectedMonth));
    }

    setFilteredData(filtered);
  };

  if (loading) return <p>Lade Daten...</p>;
  if (error) return <p>{error}</p>;

  // Daten für den Chart vorbereiten
  const labels = filteredData.map(day => new Date(day.date).toLocaleDateString());
  const uniqueVisitors = filteredData.map(day => day.uniqueVisitors);
  const pageViews = filteredData.map(day => day.pageViews);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Besucher',
        data: uniqueVisitors,
        backgroundColor: 'rgba(255, 99, 132, 0.6)', // Rot
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Seitenaufrufe',
        data: pageViews,
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blau
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: `Besucherdaten für ${year !== 'all' ? year : 'alle Jahre'} ${month !== 'all' ? `Monat ${month}` : ''}`,
      },
    },
  };

  return (
    <Box sx={{ padding: 1 }}>
      {/* Filter für Jahr */}
      <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
        <InputLabel id="year-label">Jahr</InputLabel>
        <Select
          labelId="year-label"
          id="year-select"
          value={year}
          label="Jahr"
          onChange={handleYearChange}
        >
          <MenuItem value="all">Alle</MenuItem>
          {years.map((yearValue) => (
            <MenuItem key={yearValue} value={yearValue}>{yearValue}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Filter für Monat */}
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="month-label">Monat</InputLabel>
        <Select
          labelId="month-label"
          id="month-select"
          size="sm"
          value={month}
          label="Monat"
          onChange={handleMonthChange}
        >
          <MenuItem value="all">Alle</MenuItem>
          {months.map((monthValue) => (
            <MenuItem key={monthValue} value={monthValue}>{monthValue}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Bar Chart */}
      <Bar data={data} options={options} />
    </Box>
  );
};

export default VisitorBarChart;
