import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { toast } from 'react-toastify';

// Importiere die neuen Unterkomponenten aus dem korrekten Verzeichnis
import TournamentHeader from '../components/turniere/TournamentHeader';
import TournamentInfoBox from '../components/turniere/TournamentInfoBox';
import TournamentRanking from '../components/turniere/TournamentRanking';
import TournamentActions from '../components/turniere/TournamentActions';
import ParticipantManager from '../components/turniere/ParticipantManager';

// Importiere die Modal-Komponenten (Pfade waren wahrscheinlich schon korrekt)
import TournamentEdit from '../components/turniere/TournamentEdit';
import PartificantAdd from '../components/turniere/partificantAdd';
import TournamentDelete from '../components/turniere/tournamentDelete'; // Wird aktuell nicht getriggert? Überprüfen.
import ParticipantShow from "../components/turniere/partificantShow";
import TournamentClose from '../components/turniere/tournamentClose';
import ConfirmModal from '../components/turniere/ConfirmModal';
import SendEmail from '../components/turniere/sendEmail';

// Hauptkomponente
function Tournaments() {
    const [tournament, setTournament] = useState(null); // Initial null statt {}
    const [loading, setLoading] = useState(true);
    const [participants, setParticipants] = useState([]);
    const [participantsConfirmed, setParticipantsConfirmed] = useState([]);
    const [participantsWait, setParticipantsWait] = useState([]);
    const [participantsCanceled, setParticipantsCanceled] = useState([]);
    const [inquiry, setInquiry] = useState([]);

    // State für Modals
    const [editTournamentModal, setEditTournamentModal] = useState(false);
    const [addPartificantModal, setAddPartificantModal] = useState(false);
    const [deleteTournamentModal, setDeleteTournamentModal] = useState(false); // Wird das verwendet?
    const [showParticipantModal, setShowParticipantModal] = useState(false);
    const [showTournamentCloseModal, setShowTournamentCloseModal] = useState(false);
    const [showSendEmailModal, setShowSendEmailModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // State für Datenübergabe an Modals
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [confirmModalTopic, setConfirmModalTopic] = useState(0);

    const { id } = useParams();

    // --- DATENABRUF ---

    // Verwende useCallback, um unnötige Neudefinitionen der Funktionen zu vermeiden
    const fetchParticipants = useCallback((tournamentId, totalTeams) => {
        axios.get(`/tournament/${tournamentId}/participants`)
            .then((response) => {
                const data = response.data.data || [];
                setParticipants(data);
                setParticipantsConfirmed(data.filter(p => p.status === 1));
                setParticipantsCanceled(data.filter(p => p.status === 3));
                setParticipantsWait(data.filter(p => p.status === 2));
                setInquiry(data.filter(p => p.status === 0 || !p.status));
                setLoading(false); // Ladevorgang hier beenden, nachdem beide Calls durch sind
            })
            .catch((error) => {
                console.error("Fehler beim Laden der Teilnehmer:", error);
                toast.error("Fehler beim Laden der Teilnehmerliste.");
                setLoading(false); // Auch im Fehlerfall Loading beenden
            });
    }, []); // Leeres Abhängigkeitsarray, da 'id' indirekt über fetchTournamentData kommt

    const fetchTournamentData = useCallback(() => {
        setLoading(true);
        axios.get(`/tournament/${id}`)
            .then((response) => {
                setTournament(response.data);
                // Lade Teilnehmer erst, wenn Turnierdaten vorhanden sind
                fetchParticipants(id, response.data.teams); // ID hier übergeben
            })
            .catch((error) => {
                console.error("Fehler beim Laden des Turniers:", error);
                toast.error("Fehler beim Laden der Turnierdetails.");
                setTournament(null); // Setze Turnier auf null im Fehlerfall
                setLoading(false);
            });
    }, [id, fetchParticipants]); // fetchParticipants als Abhängigkeit hinzufügen

    useEffect(() => {
        fetchTournamentData();
    }, [fetchTournamentData]); // Wird bei ID-Änderung neu getriggert

    // --- HANDLER FUNKTIONEN ---

    const handleRefreshParticipants = () => {
        if (tournament) {
            // Rufe fetchParticipants direkt auf, statt über fetchTournamentData
            setLoading(true); // Zeige Ladeindikator während des Refreshs
            fetchParticipants(id, tournament.teams);
        }
    };

    const handleConfirmModalHide = (reload) => {
        setShowConfirmModal(false);
        if (reload) {
            handleRefreshParticipants();
        }
        setSelectedParticipant(null);
    };

    const handleParticipantUpdateAction = (participant, topic) => {
        setSelectedParticipant(participant);
        let modalTopic = topic;
        if (topic === 3 && participantsConfirmed.length >= (tournament?.teams || 0)) {
            modalTopic = 4;
        } else if (topic === 1 && participant.paid) {
            modalTopic = 2;
        }
        setConfirmModalTopic(modalTopic);
        setShowConfirmModal(true);
    };

    const handleParticipantDeleteAction = (participantId) => {
        // Optional: Bestätigungsdialog vor dem Löschen
        setLoading(true);
        axios.delete(`/tournament/${id}/participants/${participantId}`)
            .then(() => {
                toast.success("Teilnehmer erfolgreich gelöscht.");
                handleRefreshParticipants();
            })
            .catch((error) => {
                setLoading(false);
                console.error("Fehler beim Löschen des Teilnehmers:", error);
                toast.error("Fehler beim Löschen des Teilnehmers.");
            });
    };

    const handleParticipantDownloadAction = () => {
        if (!tournament) return;
        setLoading(true);
        const sanitizedTournamentName = tournament.name
            .replace(/[\\/:*?"<>|]/g, '')
            .replace(/\s+/g, '_')
            .trim() || 'Turnier';
        const filename = `${sanitizedTournamentName}-Teilnehmerliste.xlsx`;

        axios.get(`/tournament/${id}/participants/download`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Fehler beim Herunterladen:", error);
                toast.error('Fehler beim Herunterladen der Datei.');
            });
    };

    const handlePublicationAction = () => {
        if (!tournament) return;
        // Optional: Bestätigungsdialog
        setLoading(true);
        axios.post(`/mail/send-invites/${id}`)
            .then(() => {
                toast.success("Einladungen zur Veröffentlichung gesendet.");
                // Turnierdaten neu laden, um 'published' Status zu aktualisieren
                fetchTournamentData();
            })
            .catch((error) => {
                console.error("Fehler bei der Veröffentlichung:", error);
                toast.error("Fehler bei der Veröffentlichung.");
                setLoading(false);
            });
    };

    const handleToggleRegistrationAction = () => {
        if (!tournament) return;
        if (!tournament.published) {
            toast.error('Turnier wurde noch nicht veröffentlicht.');
            return;
        }
        setLoading(true);
        const newRegistrationStatus = !tournament.registration;
        axios.put(`/api/Tournaments/${id}`, { registration: newRegistrationStatus })
            .then(() => {
                toast.success(`Anmeldung ${newRegistrationStatus ? 'geöffnet' : 'geschlossen'}.`);
                // Optimistisches Update oder fetchTournamentData()
                setTournament(prev => prev ? ({ ...prev, registration: newRegistrationStatus }) : null);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Fehler beim Ändern des Anmeldestatus:", error);
                toast.error("Fehler beim Ändern des Anmeldestatus.");
            });
    };

    const handleParticipantShowAction = (participant) => {
        setSelectedParticipant(participant);
        setShowParticipantModal(true);
    };

    const handleSendMessageRequest = (participantToSend) => {
        setShowParticipantModal(false); // Schließe ParticipantShow Modal
        setSelectedParticipant(participantToSend); // Setze den Teilnehmer für das E-Mail Modal
        setShowSendEmailModal(true);  // Öffne SendEmail Modal
    };

    // --- RENDERING ---

    if (loading && !tournament) {
        return <div className="text-center rakete-font my-5">Lade Turnierdaten...</div>;
    }

    if (!tournament && !loading) { 
        return <div className="text-center rakete-font my-5 text-danger">Turnierdetails konnten nicht geladen werden.</div>;
    }

    if (!tournament) return null;

    return (
        <>
            <div className='row justify-content-center mx-3'>
                <TournamentHeader name={tournament.name} />

                <div className='col-lg-5 rounded-4 bg-white shadow-lg mt-2 mx-2 mb-3 mb-lg-0'> {/* Abstand unten für Mobile */}
                    <TournamentInfoBox
                        tournament={tournament}
                        participants={participants}
                        participantsConfirmed={participantsConfirmed}
                        participantsWait={participantsWait}
                    />
                    {tournament.completed ? (
                        <TournamentRanking ranking={tournament.ranking} />
                    ) : (
                         <TournamentActions
                            tournament={tournament}
                            onPublish={handlePublicationAction}
                            onClose={() => setShowTournamentCloseModal(true)}
                            onEdit={() => setEditTournamentModal(true)}
                            onDownload={handleParticipantDownloadAction}
                            onSendEmail={() => setShowSendEmailModal(true)}
                            onToggleRegistration={handleToggleRegistrationAction}
                        />
                    )}
                </div>

                <div className='col-lg-6 rounded-4 bg-white shadow-sm mt-2 mx-2 py-3'>
                   {/* Der Hinzufügen-Button ist jetzt im ParticipantManager */}
                    <ParticipantManager
                        tournament={tournament}
                        inquiryList={inquiry}
                        confirmedList={participantsConfirmed}
                        waitList={participantsWait}
                        canceledList={participantsCanceled}
                        onShowParticipant={handleParticipantShowAction}
                        onUpdateParticipant={handleParticipantUpdateAction}
                        onDeleteParticipant={handleParticipantDeleteAction}
                        onAddParticipant={() => setAddPartificantModal(true)}
                        loading={loading} // loading state übergeben
                    />
                </div>
            </div>

            {/* Modals */}
            <TournamentEdit
                show={editTournamentModal}
                onHide={() => setEditTournamentModal(false)}
                tournament={tournament}
                refresh={fetchTournamentData}
            />
            <PartificantAdd
                show={addPartificantModal}
                onHide={() => setAddPartificantModal(false)}
                refresh={handleRefreshParticipants}
                tournamentid={tournament._id}
            />
            {/* <TournamentDelete show={deleteTournamentModal} onHide={() => setDeleteTournamentModal(false)} id={tournament._id} /> */}
            <ParticipantShow
    show={showParticipantModal}
    onHide={() => { setShowParticipantModal(false); setSelectedParticipant(null); }}
    participant={selectedParticipant}
    tournamentId={tournament?._id} // Turnier-ID übergeben
    onSendMessage={handleSendMessageRequest} // Handler für Nachricht senden
    onRefresh={handleRefreshParticipants} // Handler zum Aktualisieren der Liste
/>
            <TournamentClose
                show={showTournamentCloseModal}
                onHide={() => setShowTournamentCloseModal(false)}
                participants={participantsConfirmed}
                tournamentId={tournament._id}
                refresh={fetchTournamentData}
            />
            <ConfirmModal
                show={showConfirmModal}
                onHide={handleConfirmModalHide}
                tournament={tournament._id} // Nur ID übergeben, falls benötigt
                participant={selectedParticipant}
                topic={confirmModalTopic}
            />
<SendEmail
     show={showSendEmailModal}
     // Stelle sicher, dass onHide hier setSelectedParticipant zurücksetzt, falls nötig
     onHide={() => { setShowSendEmailModal(false); /*setSelectedParticipant(null);*/ }}
     tournament={tournament?._id}
     participants={participants}
     participant={selectedParticipant} // Der ausgewählte Teilnehmer wird übergeben
 />
        </>
    );
}

export default Tournaments;
