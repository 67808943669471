import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';

import { useAuth } from '../provider/auth.js';


function TaskAdd({ show, onHide }) {
  const [loading, setLoading] = useState(false);
  const { rights } = useAuth();

  return (
    <Modal size="md" show={show} onHide={onHide} centered className='no-carot'>
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>Neue Aufgabe</Modal.Title>
      </Modal.Header>
      <Modal.Body className='fs-7'>
        <Formik
          initialValues={{
            description: '',
            message: '',
            deadline: '',
            phoneNumber: '',
            street: '',
            houseNumber: '',
            city: '',
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="my-2">
                <Form.Group className="my-2" as={Col}>
                  <Form.Label>Stichtag</Form.Label>
                  <Form.Control
                    id="deadline"
                    type="date"
                    className='form-control-sm'
                    name="deadline"
                    value={values.deadline}
                    onChange={handleChange}
                    isInvalid={touched.deadline && !!errors.deadline}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deadline}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Label>Intervall</Form.Label>
                  <Form.Select
                    size="sm"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    isInvalid={touched.role && !!errors.role}
                    disabled={rights !== 2}
                  >
                    <option value="">einmalig</option>
                    <option value="0">monatlich</option>
                    <option value="1">vierteljählich</option>
                    <option value="2">halbjährlich</option>
                    <option value="3">jährlich</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className="my-2">
                <Form.Label>Mitglied</Form.Label>
                <Form.Select
                  size="sm"
                  className='form-control-sm'
                  name="team"
                  value={values.user}
                  disabled={rights !== 2}
                  onChange={handleChange}

                >
                  <option value="">Mitglied wählen</option>
                  <option value="0">Erste</option>
                  <option value="1">Zweite</option>
                  <option value="2">Mixed</option>
                  <option value="3">Frauen</option>
                </Form.Select>
              </Form.Group>
              <Row className="my-2">
                <Form.Label>Abteilung</Form.Label>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Vorstand'
                    name="vorstand"
                    checked={values.active}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Turniere'
                    name="webmaster"
                    checked={values.webmaster}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Webmaster'
                    name="webmaster"
                    checked={values.webmaster}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="my-2">
                <Form.Label>Leitung</Form.Label>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Erste'
                    name="erste"
                    checked={values.erste}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Zweite'
                    name="zweite"
                    checked={values.zweite}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Mixed'
                    name="mixed"
                    checked={values.mixed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="my-2 role" as={Col}>
                  <Form.Check
                    type="checkbox"
                    label='Frauen'
                    name="frauen"
                    checked={values.frauen}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Form.Group className="my-2">
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  id="title"
                  type="text"
                  className='form-control-sm'
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  isInvalid={touched.title && !!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2">
                <Form.Label>Beschreibung</Form.Label>
                <Form.Control
                  id="description"
                  as="textarea"
                  rows={4}
                  type="text"
                  className='form-control-sm'
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isInvalid={touched.description && !!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <button
                  className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Speichern..." : "Speichern"}
                </button>
                <button
                  className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                  onClick={onHide}
                  type='button'
                >
                  Verwerfen
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default TaskAdd;