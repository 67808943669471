import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbEditCircle } from "react-icons/tb";
import Accordion from 'react-bootstrap/Accordion';
import TeamsEdit from '../components/teams/TeamsEdit';
import { IMAGE_URL } from '../App.js';

function Teams() {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [test, setTest] = useState([]);
    
    // Setze alle Items beim Laden als aktiv (offen)
    const [activeKey, setActiveKey] = useState([]);

    const handleEdit = (team) => {
        setEdit(true);
        setSelectedTeam(team);
    }

    const toggleAccordion = (index) => {
        // Schließe alle anderen und öffne nur das geklickte Accordion
        setActiveKey(activeKey.includes(index) ? [] : [index]);
    }

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/Teams`)
            .then((response) => {
                setTeams(response.data.data);
                // Setzt alle Indizes in den `activeKey`, um alle Accordeons zu Beginn offen zu halten
                setActiveKey(response.data.data.map((_, index) => index));
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
            axios.get(`/teams/ids`)
            .then((response) => {
                setTest(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className='container-fluid mt-3'>
                <div className='fw-semibold rakete-font fs-1 text-center text-uppercase headline'>Teams</div>
                <Accordion activeKey={activeKey}>
                    <div className='container-fluid'>
                        <div className=" shadow-sm rounded my-3">
                            {teams && teams.map((team, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header onClick={() => toggleAccordion(index)}>
                                        <div className='col-8 fw-semibold rakete-font fs-4 ms-3 text-uppercase'>{team.name}</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className=' fs-7 mx-1 pb-2'>
                                            <div className='text-end'>
                                                <TbEditCircle size={25} className='hover icon-edit mx-3 my-3' onClick={() => handleEdit(team)} />
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className='col-md-5 text-center'>
                                                    <img src={`${IMAGE_URL}/uploads/${team.picture}`} className="team-pic rounded card-img-top shadow-sm news-ratio" />
                                                </div>
                                                <div className='col-md-5 text-center'>
                                                    <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">TRAINING</p>
                                                    {!team.trainings || team.trainings.length === 0 ? (
                                                        <p>Keine Trainingszeiten vorhanden</p>
                                                    ) : (
                                                        <div className='py-2'>
                                                            <div className='text-center fw-semibold rakete-font mb-2 fs-7'>Sommer</div>
                                                            <table className="table table-striped">
                                                                <thead className='text-center fs-8 rakete-font'>
                                                                    <tr>
                                                                        <th>Tag</th>
                                                                        <th>Uhrzeit</th>
                                                                        <th>Dauer</th>
                                                                        <th>Ort</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='text-center'>
                                                                    {team.trainings
                                                                        .filter(training => training.season)
                                                                        .sort((a, b) => {
                                                                            const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                                            return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                                        })
                                                                        .map((training, trainingIndex) => (
                                                                            <tr key={trainingIndex}>
                                                                                <td>{training.day}</td>
                                                                                <td>{training.time} Uhr</td>
                                                                                <td>{training.duration} Std</td>
                                                                                <td>{training.location}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div className='text-center fw-semibold rakete-font mb-2 fs-7'>Winter</div>
                                                            <table className="table table-striped">
                                                                <thead className='text-center fs-8 rakete-font text-uppercase'>
                                                                    <tr>
                                                                        <th>Tag</th>
                                                                        <th>Uhrzeit</th>
                                                                        <th>Dauer</th>
                                                                        <th>Ort</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='text-center'>
                                                                    {team.trainings
                                                                        .filter(training => !training.season)
                                                                        .sort((a, b) => {
                                                                            const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                                            return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                                        })
                                                                        .map((training, trainingIndex) => (
                                                                            <tr key={trainingIndex}>
                                                                                <td>{training.day}</td>
                                                                                <td>{training.time} Uhr</td>
                                                                                <td>{training.duration} Std</td>
                                                                                <td>{training.location}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                    <div className='text-center pt-3'>
                                                        <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">ABOUT US</p>
                                                        {team.about}
                                                    </div>
                                                    <div className='text-center pt-3'>
                                                        <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">MOTTO</p>
                                                        <p className='rakete-font fw-semibold'>{team.slogan}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </div>
                    </div>
                    <TeamsEdit show={edit} onHide={() => setEdit(false)} team={selectedTeam} />
                </Accordion>
            </div>
        </>
    )
}

export default Teams;
