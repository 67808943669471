import React from 'react'
import Statistics from '../components/statistics'
import News from './News'
import History from '../components/history/History'
import Members from '../components/members/members'

function Memberboard() {
  return (
    <>
    <Members number={4} />
    </>
  )
}

export default Memberboard