import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FiMail, FiEdit, FiSave, FiXCircle } from "react-icons/fi";
import formattedDate from '../../helpers/formattedDate'; // Pfad prüfen!
import dayjs from 'dayjs';

// Hilfsfunktion zum Formatieren für das Input-Datum
const formatDateForInput = (date) => {
    if (!date) return '';
    return dayjs(date).format('YYYY-MM-DD');
};

function ParticipantShow({ show, onHide, participant, tournamentId, onSendMessage, onRefresh }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedParticipant, setEditedParticipant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Bestimme, ob der Teilnehmer als intern behandelt werden soll
    const isInternal = participant?.intern === true;

    useEffect(() => {
        if (participant) {
            setEditedParticipant({
                ...participant,
                // Kombiniertes Feld für externe Teilnehmer vorbereiten
                streetAndNumber: isInternal ? '' : `${participant.street || ''} ${participant.houseNumber || ''}`.trim()
             });
        } else {
            setEditedParticipant(null);
        }
        setIsEditing(false); // Bearbeitungsmodus immer zurücksetzen
    }, [participant, show, isInternal]); // isInternal hinzugefügt

    if (!editedParticipant) {
        return null;
    }

    const handleInputChange = (e) => {
        // Eingabe immer erlauben, aber Speichern handhabt, was gesendet wird
        const { name, value, type, checked } = e.target;
        setEditedParticipant(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    // Separater Handler für das kombinierte Straßenfeld (nur für externe relevant)
     const handleStreetNumberChange = (e) => {
         if (isInternal) return; // Sollte nicht erreichbar sein, aber sicher ist sicher
         setEditedParticipant(prev => ({
             ...prev,
             streetAndNumber: e.target.value
         }));
     };


    const handleCancelEdit = () => {
        // Ursprünglichen Zustand wiederherstellen
        if (participant) {
             setEditedParticipant({
                ...participant,
                streetAndNumber: isInternal ? '' : `${participant.street || ''} ${participant.houseNumber || ''}`.trim()
             });
        }
        setIsEditing(false);
    };

    const handleSave = async () => {
        if (!editedParticipant || !tournamentId) return;

        setIsLoading(true);
        let payload = {};
        const apiUrl = `/tournament/${tournamentId}/participants/${editedParticipant._id}`;

        try {
            if (isInternal) {
                // Payload für interne Teams: Nur Teamname
                if (!editedParticipant.teamname?.trim()) {
                     toast.error("Teamname darf nicht leer sein.");
                     setIsLoading(false);
                     return;
                 }
                payload = { teamname: editedParticipant.teamname.trim() };
                // Optional: Füge 'intern: true' hinzu, falls das Backend es erwartet/überschreiben soll
                // payload.intern = true;
            } else {
                // Payload für externe Teams: Alle relevanten Daten
                // Parse streetAndNumber zurück, falls nötig für Backend
                // const { street, houseNumber } = parseStreetAndNumber(editedParticipant.streetAndNumber || '');

                const { _id, createdAt, updatedAt, __v, streetAndNumber, intern, // Felder entfernen, die nicht gesendet werden sollen
                         ...updateData } = editedParticipant;

                // Füge ggf. geparste Felder hinzu: updateData.street = street; updateData.houseNumber = houseNumber;
                // Füge hier ggf. Validierung für externe Teams hinzu
                payload = updateData;
            }

            const response = await axios.put(apiUrl, payload);
            toast.success("Teilnehmer erfolgreich aktualisiert.");
            setIsEditing(false); // Zurück zum Ansichtsmodus

            // WICHTIG: Nach dem Speichern den Parent informieren, damit die Liste aktualisiert wird
            // und die potenziell geänderten Daten (z.B. Teamname) auch im ParticipantShow korrekt sind.
            if (onRefresh) {
                onRefresh();
            }
             // Schließe das Modal nach dem Speichern? Optional.
             // onHide();

        } catch (error) {
            console.error("Fehler beim Speichern:", error);
            toast.error(error.response?.data?.message || "Fehler beim Speichern der Teilnehmerdaten.");
        } finally {
            setIsLoading(false);
        }
    };


    const handleSendMessageClick = () => {
        if (isInternal) return; // Sollte durch disabled verhindert werden, aber sicher ist sicher
        if (onSendMessage && participant) {
            onSendMessage(participant);
        }
    };

    // Helper zum Rendern einer Zeile - wird nur für externe Teams oder Teamname verwendet
    const renderField = (label, name, type = "text", value, displayValue = null) => {
        // Bearbeitung nur möglich, wenn NICHT intern UND im Bearbeitungsmodus
        const canEdit = !isInternal && isEditing;
        // Korrekten Anzeigewert bestimmen (oder '-' wenn extern und leer)
        const finalDisplayValue = displayValue ?? value ?? '-';

        return (
            <div className='row bg-light align-items-center py-2 border-top'>
                <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">{label}</div>
                <div className="col text-start">
                    {canEdit ? (
                        <Form.Control
                            size="sm"
                            type={type}
                            name={name}
                            value={value || ''}
                            onChange={handleInputChange}
                            placeholder={label}
                            {...(type === 'date' && { value: formatDateForInput(value) })}
                        />
                    ) : (
                        <span>{finalDisplayValue}</span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Modal show={show} onHide={onHide} className="mx-auto" centered size="md">
            <Modal.Header className='border border-0 rakete-rot' closeButton>
                <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6 '>
                    {/* Zeige immer den (potentiell bearbeiteten) Teamnamen */}
                    {editedParticipant.teamname} {isInternal ? '(Intern)' : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className=''>

                {/* Teamname Zeile (immer sichtbar, editierbar auch wenn intern) */}
                <div className='row bg-light align-items-center py-2 border-top'>
                    <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Teamname</div>
                    <div className="col text-start">
                        {isEditing ? ( // Bearbeitbar auch wenn intern
                            <Form.Control
                                size="sm"
                                type="text"
                                name="teamname"
                                value={editedParticipant.teamname || ''}
                                onChange={handleInputChange}
                                placeholder="Teamname"
                            />
                        ) : (
                            <span>{editedParticipant.teamname}</span>
                        )}
                    </div>
                </div>

                {/* Andere Felder nur anzeigen, wenn NICHT intern */}
                {!isInternal && (
                    <>
                        {renderField("Vorname", "firstname", "text", editedParticipant.firstname)}
                        {renderField("Nachname", "lastname", "text", editedParticipant.lastname)}
                        {renderField("Geburtstag", "birthday", "date", editedParticipant.birthday, formattedDate(editedParticipant.birthday))}
                        {renderField("Email", "email", "email", editedParticipant.email)}

                        {/* Email 2 nur wenn nicht intern UND (Wert vorhanden ODER Edit-Modus) */}
                        {(editedParticipant.email1 || isEditing) && (
                            <div className='row bg-light align-items-center py-2 border-top'>
                                <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Email 2</div>
                                <div className="col text-start">
                                    {isEditing ? (
                                        <Form.Control
                                            size="sm" type="email" name="email1"
                                            value={editedParticipant.email1 || ''} onChange={handleInputChange}
                                            placeholder="Zweite E-Mail (optional)"
                                        />
                                    ) : (
                                        editedParticipant.email1 ? <span>{editedParticipant.email1}</span> : null
                                    )}
                                </div>
                            </div>
                        )}

                        {renderField("Telefon", "phoneNumber", "tel", editedParticipant.phoneNumber)}

                        {/* Adresse */}
                        <div className='row bg-light align-items-center py-2 border-top'>
                            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Straße & Nr.</div>
                            <div className="col text-start">
                                {isEditing ? (
                                    <Form.Control
                                        size="sm" type="text" name="streetAndNumber" // Verwende das kombinierte Feld für die Eingabe
                                        value={editedParticipant.streetAndNumber || ''}
                                        onChange={handleStreetNumberChange} // Eigener Handler
                                        placeholder="Straße und Hausnummer"
                                    />
                                ) : (
                                    <span>{`${editedParticipant.street || ''} ${editedParticipant.houseNumber || ''}`.trim() || '-'}</span>
                                )}
                            </div>
                        </div>
                         <div className='row bg-light align-items-center py-2 border-top border-bottom'> {/* Letzte Zeile hat border-bottom */}
                            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">PLZ / Ort</div>
                            <div className="col text-start">
                                {isEditing ? (
                                    <div className="row g-2">
                                        <div className="col-4">
                                            <Form.Control size="sm" type="text" name="postalCode" value={editedParticipant.postalCode || ''} onChange={handleInputChange} placeholder="PLZ"/>
                                        </div>
                                        <div className="col-8">
                                            <Form.Control size="sm" type="text" name="city" value={editedParticipant.city || ''} onChange={handleInputChange} placeholder="Stadt"/>
                                        </div>
                                    </div>
                                ) : (
                                    <span>{`${editedParticipant.postalCode || ''} ${editedParticipant.city || ''}`.trim() || '-'}</span>
                                )}
                            </div>
                        </div>
                    </>
                )}
                 {/* Wenn intern, füge eine leere Zeile mit Border hinzu für konsistenten Abschluss */}
                 {isInternal && <div className="border-bottom"></div>}

            </Modal.Body>
            <Modal.Footer className='border border-0'>
                {isEditing ? (
                    <>
                        <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" type="button" onClick={handleCancelEdit} disabled={isLoading}>
                             <FiXCircle className="me-1"/> Abbrechen
                        </button>
                        <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" type="button" onClick={handleSave} disabled={isLoading}>
                            {isLoading ? 'SPEICHERT...' : <><FiSave className="me-1"/> Speichern</>}
                        </button>
                    </>
                ) : (
                    <>
                        {/* Nachricht senden: Deaktiviert, wenn intern */}
                        {!isInternal && 
                        <button
                            className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                            type="button"
                            onClick={handleSendMessageClick}
                            
                            title={"E-Mail senden"}
                        >
                             <FiMail className="me-1"/> Nachricht senden
                        </button>}
                        {/* Bearbeiten: Immer sichtbar (außer im Edit-Modus), onClick löst Edit aus */}
                        <button
                            className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                            type="button"
                            onClick={() => setIsEditing(true)}
                            // Nicht mehr deaktiviert, wenn intern
                        >
                             <FiEdit className="me-1"/> Bearbeiten
                        </button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}
export default ParticipantShow;
