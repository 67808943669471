import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FiSend, FiXCircle } from "react-icons/fi";

function SendEmail({ show, onHide, tournament, participants, participant }) {
  const [loading, setLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [newEmail, setNewEmail] = useState({ subject: '', content: '' });
  const [selectedTemplate, setSelectedTemplate] = useState('new');
  const [useTemplate, setUseTemplate] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState('all');
  const [isRecipientInternal, setIsRecipientInternal] = useState(false);

  const quillRef = useRef(null);

  const placeholderMap = [
      { placeholder: '{{firstname}}', alias: 'Vorname' },
      { placeholder: '{{lastname}}', alias: 'Nachname' },
      { placeholder: '{{teamname}}', alias: 'Teamname' },
      { placeholder: '{{email}}', alias: 'E-Mail' },
      { placeholder: '{{tournament_name}}', alias: 'Turniername' },
      { placeholder: '{{tournament_location}}', alias: 'Ort' },
      { placeholder: '{{tournament_date}}', alias: 'Datum' },
      { placeholder: '{{tournament_time}}', alias: 'Zeit' },
      { placeholder: '{{tournament_prize}}', alias: 'Gebühr' },
      { placeholder: '{{tournament_signup}}', alias: 'Anmelde-Button' },
      { placeholder: '{{tournament_info}}', alias: 'Info-Button' },
  ];

  const stripHtml = (html) => { if (!html) return ""; let tmp = document.createElement("DIV"); tmp.innerHTML = html; return tmp.textContent || tmp.innerText || ""; };

  useEffect(() => {
      if (show) {
          fetchEmailTemplates();
          const initialParticipantId = participant?._id || 'all';
          setSelectedParticipant(initialParticipantId);
          handleTemplateChange(selectedTemplate || 'new');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, participant]);

   useEffect(() => {
      if (selectedParticipant === 'all' || !participants || participants.length === 0) {
          setIsRecipientInternal(false);
      } else {
          const recipient = participants.find(p => p._id === selectedParticipant);
          setIsRecipientInternal(recipient?.intern === true);
      }
  }, [selectedParticipant, participants]);


  const fetchEmailTemplates = async () => {
    try {
      const response = await axios.get(`/api/EmailTemplate`);
      const templates = response.data?.data;
      if (Array.isArray(templates)) {
          setEmailTemplates(templates.filter(template => template.deletable) || []);
      } else {
          setEmailTemplates([]);
      }
    } catch (error) {
        console.error("Fehler beim Laden der Email-Templates:", error);
        toast.error("Fehler beim Laden der Email-Templates");
        setEmailTemplates([]);
     }
  };

  const resetForm = () => {
      setNewEmail({ subject: '', content: '' });
      setSelectedTemplate('new');
      setUseTemplate(false);
      setSelectedParticipant(participant?._id || 'all');
      setIsRecipientInternal(participant?.intern === true);
  };

  const handleTemplateChange = (eventOrValue) => {
      const selectedId = typeof eventOrValue === 'string' ? eventOrValue : eventOrValue.target.value;
      setSelectedTemplate(selectedId);
      setUseTemplate(false);
      if (selectedId === 'new') {
          setNewEmail({ subject: '', content: '' });
      } else {
          const template = Array.isArray(emailTemplates) ? emailTemplates.find(t => t._id === selectedId) : undefined;
          if (template) {
              setNewEmail({ subject: template.subject || '', content: template.content || '' });
          } else if (selectedId !== 'new') {
              setNewEmail({ subject: '', content: '' });
              console.warn(`Template mit ID ${selectedId} nicht gefunden.`);
          }
      }
  };

  const handleUseTemplate = (checked) => {
      setUseTemplate(checked);
      if (!checked && selectedTemplate !== 'new') {
          const template = Array.isArray(emailTemplates) ? emailTemplates.find(t => t._id === selectedTemplate) : undefined;
          if (template) { setNewEmail({ subject: template.subject || '', content: template.content || '' }); }
      }
  };

  const sendEmail = async () => {
      if (isRecipientInternal) { toast.warn("E-Mails können nicht an interne Teams gesendet werden."); return; }
      const subjectIsEmpty = !newEmail.subject || !newEmail.subject.trim();
      const contentIsEmpty = !newEmail.content || !stripHtml(newEmail.content).trim();
      if (subjectIsEmpty || contentIsEmpty) { toast.error("Betreff und Inhalt dürfen nicht leer sein."); return; }
      setLoading(true);
      try {
          const payload = { emailTemplate: { subject: newEmail.subject, content: newEmail.content } };
          let url = selectedParticipant === 'all' ? `/mail/send-email/${tournament}` : `/mail/send-email/${tournament}/${selectedParticipant}`;
          await axios.post(url, payload);
          toast.success("E-Mail erfolgreich gesendet!");
          onHide();
      } catch (error) {
          console.error("Fehler beim Senden der E-Mail:", error);
          const errorMsg = error.response?.data?.message || "E-Mail konnte nicht gesendet werden.";
          toast.error(errorMsg);
       }
      finally { setLoading(false); }
  };

  const handleHide = () => { resetForm(); onHide(); };

  const insertPlaceholder = (placeholder) => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertText(range.index, placeholder, 'user');
        quill.setSelection(range.index + placeholder.length, 0, 'user');
      } else {
        // Fallback: Wenn keine Auswahl/Cursor da ist, am Ende einfügen
        const length = quill.getLength();
        quill.insertText(length, placeholder, 'user');
        quill.setSelection(length + placeholder.length, 0, 'user');
      }
      quill.focus();
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleHide} >
      <Modal.Header closeButton className='border border-0 rakete-rot'>
        <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6'>Nachricht senden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="my-2">
          <Form.Label>An</Form.Label>
          <Form.Select value={selectedParticipant} onChange={(e) => setSelectedParticipant(e.target.value)}>
    {participant && participant.intern !== true && (
         <option key={participant._id} value={participant._id}>{participant.teamname}</option>
    )}

    <option value="all">Alle Teilnehmer</option>

    {participants && Array.isArray(participants) && participants

        .filter(p => (!participant || p._id !== participant._id) && p.intern !== true)
        .map(p => (
         <option key={p._id} value={p._id}>{p.teamname}</option>
     ))}
</Form.Select>
        </Form.Group>

        <Form.Group className="my-2">
          <Form.Label>Email Vorlage</Form.Label>
          <Form.Select value={selectedTemplate} onChange={handleTemplateChange} className='mb-4'>
            <option value="new">Neue E-Mail schreiben</option>
            {emailTemplates && Array.isArray(emailTemplates) && emailTemplates.map(template => (
              <option key={template._id} value={template._id}>{template.title}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <div className='border px-3 py-3 rounded-4 bg-light'>
          <Form.Group>
            <Form.Label className='fw-semibold'>Betreff</Form.Label>
            <Form.Control
              type="text"
              value={newEmail.subject}
              onChange={(e) => setNewEmail(prev => ({ ...prev, subject: e.target.value }))}
              placeholder="Betreff..."
              readOnly={selectedTemplate !== 'new' && !useTemplate}
              className={(selectedTemplate !== 'new' && !useTemplate) ? 'bg-light' : ''}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className='fw-semibold'>Inhalt</Form.Label>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={newEmail.content}
              placeholder="Schreibe hier..."
              onChange={(value) => setNewEmail(prev => ({ ...prev, content: value }))}
              readOnly={selectedTemplate !== 'new' && !useTemplate}
              className={(selectedTemplate !== 'new' && !useTemplate) ? 'quill-readonly-visual-feedback bg-light' : 'bg-white'}
            />
             <style>{`
                .quill-readonly-visual-feedback .ql-editor {
                  background-color: #e9ecef !important;
                  cursor: not-allowed;
                }
              `}</style>

             <div className="mt-2 pt-2 border-top d-flex flex-wrap justify-content-start align-items-center">
              <div className='row'>
                <div className='col-1 small text-muted'>Einfügen:</div>
              </div>
              <div className='col-11'>{placeholderMap.map(({ placeholder, alias }) => (
                     <button
                         key={placeholder}
                         type="button"
                         className="btn btn-outline-secondary btn-sm m-1 py-0 px-2"
                         onClick={() => insertPlaceholder(placeholder)}
                         title={`Platzhalter ${placeholder} einfügen`}
                         // Deaktiviere Buttons, wenn Editor readonly ist
                         disabled={selectedTemplate !== 'new' && !useTemplate}
                     >
                         {alias}
                     </button>
                 ))}</div>
             </div>
          </Form.Group>

          {selectedTemplate !== "new" && (
            <Form.Group className="mt-3 ms-2">
              <Form.Check
                type="checkbox"
                label="Vorlage bearbeiten"
                checked={useTemplate}
                onChange={(e) => handleUseTemplate(e.target.checked)}
              />
            </Form.Group>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className='border border-0'>
            <button
                className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                onClick={sendEmail} type="button" disabled={loading || isRecipientInternal}
                title={isRecipientInternal ? "Senden an interne Teams nicht möglich" : "E-Mail senden"} >
                {loading ? 'SENDET...' : <><FiSend className="me-1"/> SENDEN</>}
            </button>
            <button
                className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                onClick={handleHide} type="button" >
                 <FiXCircle className="me-1"/> Schließen
            </button>
        </Modal.Footer>
    </Modal>
  );
}

export default SendEmail;
