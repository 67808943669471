import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { FaTimes } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IMAGE_URL } from '../../App.js';

function NewsEdit({ show, onHide, news, id }) {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [picture, setPicture] = useState("");
  const [preview, setPreview] = useState("");
  const [gallery, setGallery] = useState([]);
  const [previewGallery, setPreviewGallery] = useState([]);
  const [removedGalleryImages, setRemovedGalleryImages] = useState([]); // Neu: Liste der zu entfernenden Bilder
  const [loading, setLoading] = useState(false);
  const [showGalleryInput, setShowGalleryInput] = useState(false);
  const refresh = true;

  useEffect(() => {
    if (news) {
      setTitle(news.title || "");
      setSubtitle(news.subtitle || "");
      setContent(news.content || "");
      setAuthor(news.author || "");
      setPicture(news.picture || "");
      setPreview(news.picture ? `${IMAGE_URL}/uploads/${news.picture}` : "");
      setGallery(news.gallery || []);
      setPreviewGallery(news.gallery ? news.gallery.map(file => `${IMAGE_URL}/uploads/${file}`) : []);
      if (news.gallery && news.gallery.length !== 0) {
        setShowGalleryInput(true);
      }
    }
  }, [news]);

  const closeNewsEdit = () => {
    onHide();
    setTitle(news.title || "");
      setSubtitle(news.subtitle || "");
      setContent(news.content || "");
      setAuthor(news.author || "");
      setPicture(news.picture || "");
      setPreview(news.picture ? `${IMAGE_URL}/uploads/${news.picture}` : "");
      setGallery(news.gallery || []);
      setPreviewGallery(news.gallery ? news.gallery.map(file => `${IMAGE_URL}/uploads/${file}`) : []);
      if (news.gallery && news.gallery.length !== 0) {
        setShowGalleryInput(true);
      }
  };

  const handleChange = (event) => {
    setPicture(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const handleGalleryChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (newFiles.length === 0) return;

    const updatedGallery = [...gallery];
    const updatedPreviewGallery = [...previewGallery];

    for (const file of newFiles) {
      const isFileInGallery = updatedGallery.some(
        (existingFile) => existingFile.name === file.name && existingFile.size === file.size
      );
      if (!isFileInGallery) {
        try {
          const objectUrl = URL.createObjectURL(file);
          updatedPreviewGallery.push(objectUrl);
          updatedGallery.push(file);
        } catch (error) {
          console.error('Fehler beim Erstellen der URL:', error);
        }
      }
    }

    setGallery(updatedGallery);
    setPreviewGallery(updatedPreviewGallery);
  };

  // Entfernt ein Bild aus der Galerie und fügt es zur Liste der zu entfernenden Bilder hinzu
  const removeImage = (index) => {
    setRemovedGalleryImages((prev) => [...prev, gallery[index]]);
    setPreviewGallery((prev) => prev.filter((_, i) => i !== index));
    setGallery((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditNews = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('author', author);
    formData.append('content', content);
    if (picture instanceof File) {
      formData.append('picture', picture);
    }
    gallery.forEach(file => formData.append('gallery', file));
    formData.append('removedGalleryImages', JSON.stringify(removedGalleryImages)); // Neu: Entfernte Bilder hinzufügen

    setLoading(true);

    axios
      .put(`/api/News/${id}`, formData)
      .then(() => {
        setLoading(false);
        onHide(refresh);

      })
      .catch((error) => {
        setLoading(false);
        alert("An error occurred. Please check Console");
      });
  };

  if (!news) {
    return null;
  }
  const handleChangeQuill = (value) => {
    setContent(value);
  };



  return (
    <Modal show={show} onHide={closeNewsEdit} size="lg">
      <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeNewsEdit}>
        News bearbeiten
      </Modal.Header>
      <Modal.Body>
        {preview && (
          <img src={preview} className="img-fluid rounded shadow-sm border mb-3" alt={`News: ${title}`} />
        )}
        <div id="fileUpload" className="mb-3">
          <div className='fw-semibold fs-7 mb-2'>Titelbild</div>
          <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
        </div>
        <div className="mb-3">
          <Form.Label>Titel</Form.Label>
          <Form.Control
            id="title"
            placeholder="Hier könnte ein geiler Titel stehen..."
            type="text"
            className='form-control-sm'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Form.Label>Zweiter Titel</Form.Label>
          <Form.Control
            id="subtitle"
            placeholder="Untertitel hinzufügen..."
            type="text"
            className='form-control-sm'
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Form.Label>Artikel</Form.Label>
          <ReactQuill
                      value={content}
                      onChange={handleChangeQuill}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['bold', 'italic', 'underline'],
                          [{ 'align': [] }],
                          ['link'],
                          [{ 'color': [] }, { 'background': [] }],
                        ],
                      }}
                      placeholder="Schreibe hier..."
                    />
        </div>
        <div className='mb-3'>
          <Form.Check
            type="switch"
            id="gallerie"
            label="Galerie"
            checked={showGalleryInput}
            onChange={() => setShowGalleryInput(!showGalleryInput)}
          />
        </div>
        {showGalleryInput && (
          <div id="fileUpload" className="mb-3">
            <Form.Control className="form-control-sm" id="file" type="file" onChange={handleGalleryChange} accept="image/*" multiple />
          </div>
        )}
        <div className="row justify-content-center align-items-center mt-3">
          {previewGallery.map((picture, index) => (
            <div className='col-md-auto' key={index}>
              <div className='thumbnail'>
                <img src={picture} alt={`preview-${index}`} className='img-fluid' />
                <span className="remove-icon" onClick={() => removeImage(index)}>
                  <FaTimes />
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleEditNews} type="submit" disabled={loading}>
          Speichern
        </button>
        <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeNewsEdit} disabled={loading}>
          Verwerfen
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewsEdit;
