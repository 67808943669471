import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./provider/auth.js";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Home from './pages/Home.jsx';
import Dashboard from "./pages/Dashboard.jsx";
import News from "./pages/News.jsx";
import Club from "./pages/Club.jsx";
import Events from "./pages/Events.jsx";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Tournaments from "./pages/Tournaments.jsx";
import Memberboard from "./pages/Members.jsx";
import Teams from "./pages/Teams.jsx";
import Team from './pages/Team.jsx';
import Website from "./pages/Website.jsx";

const BASE_URL = 'https://club.rakete-freiburg.de';
export const IMAGE_URL = `${BASE_URL}/api/images`;

axios.defaults.baseURL = 'https://club.rakete-freiburg.de/api';
axios.defaults.withCredentials = true;

function App() {
  
  return (
    <div className="bg-b">
    <div className="App max-size mx-auto pb-3">
    <BrowserRouter>
      <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Home/>
        <Routes>
          <Route path="/*" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
          <Route path="/club" element={<Club />} />
          <Route path="/events" element={<Events />} />
          <Route path="/members" element={<Memberboard />} />
          <Route path="/tournament/:id" element={<Tournaments />} />
          <Route path="/teams" element={<Teams/>} />
          <Route path="/team/:id" element={<Team/>} />
          <Route path="/website" element={<Website/>} />
        </Routes>
        </LocalizationProvider>
      </AuthProvider>
      <ToastContainer position="bottom-right" />
    </BrowserRouter>
    </div>
    </div>
  );
}

export default App;
