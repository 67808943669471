import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MemberDetail from './memberDetail';
import { getRoleLabel } from '../../helpers/role';
import MemberAdd from './memberAdd';
import Form from 'react-bootstrap/Form';
import { FaSortAmountUp } from "react-icons/fa";
import { FaSortAmountDown } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import TextField from '@mui/material/TextField';
import { useAuth } from '../../provider/auth.js';
import { IMAGE_URL } from '../../App.js';



function Members(number) {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [showMember, setShowMember] = useState(false);
    const [addMember, setAddMember] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [teamFilter, setTeamFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    const { rights } = useAuth();

    useEffect(() => {
        if(number.number || number.number === 0) {
            loadMembers(number.number);
        }
    }, [number.number]);

    useEffect(() => {
        handleFilterAndSort();
    }, [members, searchTerm, sortOrder, teamFilter, roleFilter]);

    const loadMembers = (id) => {
        setLoading(true);
        if(rights === 2 && id === 4){
            axios
            .get(`/members/all`)
            .then((response) => {
                const members = response.data.data;
                const filteredMembers = members.filter(member => {
                    return !member.exit || new Date(member.exit) > new Date();
                });
                setMembers(filteredMembers);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });

        } else {
        axios
            .get(`/members/team/${id}`)
            .then((response) => {
                const members = response.data.data;
                const filteredMembers = members.filter(member => {
                    return !member.exit || new Date(member.exit) > new Date();
                });
                setMembers(filteredMembers);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });}
    };

    const handleMemberDetail = (id) => {
        setShowMember(true);
        setSelectedMemberId(id);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (order) => {
        setSortOrder(order);
    };

    const handleRoleFilter = (e) => {
        setRoleFilter(e.target.value);
    };

    const handleFilterAndSort = () => {
        let filtered = [...members];

        // Suche
        if (searchTerm) {
            filtered = filtered.filter(member =>
                member.forename.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Filter nach Mannschaft
        if (teamFilter !== '') {
            filtered = filtered.filter(member => member.team === parseInt(teamFilter));
        }

        // Filter nach Rolle
        if (roleFilter === 'vorstand') {
            filtered = filtered.filter(member => [1, 2, 3].includes(member.role));
        } else if (roleFilter === 'leitung') {
            filtered = filtered.filter(member => [4, 5, 6, 7].includes(member.role));
        } else if (roleFilter === 'andere') {
            filtered = filtered.filter(member => [8, 9, 10].includes(member.role));
        }

        // Zuerst sortieren nach dem gefilterten Wert (Team oder Rolle)
        if (roleFilter) {
            filtered = filtered.sort((a, b) => a.role - b.role);
        } else if (teamFilter !== '') {
            filtered = filtered.sort((a, b) => a.team - b.team);
        }

        // Sortieren nach Eintrittsdatum (auf- oder absteigend)
        if (sortOrder === 'asc') {
            filtered = filtered.sort((a, b) => {
                const dateA = (a.entryDate && a.entryDate !== "undefined") ? new Date(a.entryDate) : null;
                const dateB = (b.entryDate && b.entryDate !== "undefined") ? new Date(b.entryDate) : null;

                if (!dateA) return 1;  // Kein gültiges Datum -> ans Ende der Liste
                if (!dateB) return -1;
                return dateA - dateB;
            });
        } else if (sortOrder === 'desc') {
            filtered = filtered.sort((a, b) => {
                const dateA = (a.entryDate && a.entryDate !== "undefined") ? new Date(a.entryDate) : null;
                const dateB = (b.entryDate && b.entryDate !== "undefined") ? new Date(b.entryDate) : null;

                if (!dateA) return 1;  // Kein gültiges Datum -> ans Ende der Liste
                if (!dateB) return -1;
                return dateB - dateA;
            });
        }

        setFilteredMembers(filtered);
    };


    return (
        <div className='container-fluid'>
            <div className='row justify-content-center d-flex'>
                <div className="px-3 py-3">
                    <div className='mx-auto bg-white pt-3 pb-5 rounded-5 bg-light'>
                        <p className='fw-semibold rakete-font fs-3 text-center text-uppercase text-danger pt-2'>Mitglieder</p>
                        <div className='text-center align-items-center justify-content-center d-flex my-4 mx-3 bg-white'>
                            <TextField id="searchTerm" label="Suche" variant="outlined" value={searchTerm} onChange={handleSearch} sx={{ width: '300px', height: '50px' }} />
                            {rights === 2 && <IoMdPersonAdd size={32} className='hover icon-edit mx-3' onClick={() => setAddMember(true)} />}
                        </div>
                        <div className='d-flex justify-content-center row py-3'>
                            <div className='col-auto'>
                                <Form.Group>
                                    <Form.Label className="fs-8 rakete-font fw-semibold">FUNKTION</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        type="text"
                                        className='form-control-sm'
                                        value={roleFilter}
                                        onChange={handleRoleFilter}
                                    >
                                        <option value="">Keine</option>
                                        <option value="vorstand">Vorstand</option>
                                        <option value="leitung">Leitung</option>
                                        <option value="andere">Andere</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col-auto'>
                                <Form.Group>
                                    <Form.Label className="fs-8 rakete-font fw-semibold">EINTRITT</Form.Label>
                                    <div>
                                        <FaSortAmountDown className='me-2' size={22} onClick={() => handleSort('asc')} />
                                        <FaSortAmountUp className='ms-2' size={22} onClick={() => handleSort('desc')} />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='fs-7 pb-2 bg-white px-4 member-container border'                    >
                            <div className="text-center">
                                {filteredMembers.length === 0 ?
                                    <div className='fw-semibold fs-4 py-5 text-secondary rakete-font'>Kein Mitglied gefunden</div> : filteredMembers.map((member) => (
                                        <div key={member._id} className=" row py-2 d-flex border-top align-items-center justify-content-center" onClick={() => handleMemberDetail(member._id)}>
                                            <div className='col-auto my-2'>
                                                <img className={member.rights === 1 ? " avatar-p border border-2 border-warning" : member.rights === 2 ? " avatar-p border border-2 border-primary" : `avatar-p border border-2`} src={`${IMAGE_URL}/uploads/${member.picture}`} alt={`${member.forename}`} />
                                            </div>
                                            <div className='col fs-7 text-start text-wrap fw-semibold'>{member.forename} {member.lastname}</div>
                                            <div className='col-3 fs-8 text-start fw-semibold text-end'>{member.role !== 0 && member.role && getRoleLabel(member.role)}</div>
                                            <div className={member.active === true ? "col-2 fs-8 rakete-font fw-semibold text-success" : "col-2 fs-8 rakete-font fw-semibold text-warning"}>{member.active === true ? "AKTIV" : "PASSIV"}</div>
                                        </div>
                                    ))}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <MemberDetail show={showMember} onHide={() => setShowMember(false)} member={members.find(item => item._id === selectedMemberId)} />
            <MemberAdd show={addMember} onHide={() => setAddMember(false)} setMembers={setMembers} number={number.id} />
        </div>
    );
}

export default Members;
