import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useAuth } from '../../provider/auth.js';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function NewsCreate({ show, onHide }) {
  const { user } = useAuth();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [picture, setPicture] = useState('');
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState();
  const [author, setAuthor] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [gallery, setGallery] = useState([]);
  const [showGalleryInput, setShowGalleryInput] = useState(false);
  const [previewGallery, setPreviewGallery] = useState([]);
  const refresh = true;

  // Schließt das Modal und setzt alle Felder zurück
  const closeNewsCreate = (test) => {
    setTitle("");
    setContent("");
    setPicture("");
    setAuthor("");
    setSubtitle("");
    setPreview();
    setGallery([]);
    setPreviewGallery([]);
    setShowGalleryInput(false);
    onHide(test);
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`/members/${user}`)
      .then((response) => {
        setAuthor(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [user]);

  function handleChange(event) {
    setPicture(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleChangeQuill = (value) => {
    setContent(value);
  };

  function handleGalleryChange(event) {
    const newFiles = Array.from(event.target.files);
    if (newFiles.length === 0) return;

    const updatedGallery = [...gallery];
    const updatedPreviewGallery = [...previewGallery];

    for (const file of newFiles) {
      const isFileInGallery = updatedGallery.some(
        (existingFile) => existingFile.name === file.name && existingFile.size === file.size
      );
      if (!isFileInGallery) {
        try {
          const objectUrl = URL.createObjectURL(file);
          updatedPreviewGallery.push(objectUrl);
          updatedGallery.push(file);
        } catch (error) {
          console.error('Fehler beim Erstellen der URL:', error);
        }
      }
    }

    setGallery(updatedGallery);
    setPreviewGallery(updatedPreviewGallery);
  }

  // Speichert die News
  const handleSaveNews = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('authorId', user);
    formData.append("author", author.forename + " " + author.lastname);
    formData.append('content', content);
    formData.append('picture', picture);
    formData.append('subtitle', subtitle);
    gallery.forEach(file => formData.append('gallery', file));

    setLoading(true);

    axios
      .post("/api/News", formData)
      .then(() => {
        setLoading(false);
        closeNewsCreate(refresh);
        setPreview();
        toast.done("News created");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occured. Please check Console");
        console.log(error);
      });
  };

  // Entfernt ein Bild aus der Galerie
  const removeImage = (index) => {
    setPreviewGallery((prev) => prev.filter((_, i) => i !== index));
    setGallery((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={closeNewsCreate} size="lg">
      <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeNewsCreate}>
        Neue Newsline
      </Modal.Header>
      <Modal.Body>
        <img src={preview} className="img-fluid img-preview" alt="preview" />
        <div id="fileUpload">
          <div className='fw-semibold fs-7 mt-3 mb-2'>Titelbild</div>
          <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
        </div>
        <div>
          <div className='fw-semibold fs-7 mt-3 mb-2'>Titel</div>
          <Form.Control
            id="title"
            placeholder="Hier könnte ein geiler Titel stehen..."
            type="text"
            className='form-control-sm'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <div className='fw-semibold fs-7 mt-3 mb-2'>Zweiter Titel</div>
          <Form.Control
            id="title"
            placeholder="Hier könnte ein geiler Titel stehen..."
            type="text"
            className='form-control-sm'
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
          />
        </div>
        <div>
          <div className='fw-semibold fs-7 mt-3 mb-2'>Artikel</div>
          <ReactQuill
            value={content}
            onChange={handleChangeQuill}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'align': [] }],
                ['link'],
                [{ 'color': [] }, { 'background': [] }],
              ],
            }}
            placeholder="Schreibe hier..."
          />
        </div>
        <div className='my-3'>
          <Form.Check
            type="switch"
            id="gallerie"
            label="Galerie"
            value={showGalleryInput}
            onChange={(e) => setShowGalleryInput(!showGalleryInput)}
          />
        </div>
        {showGalleryInput && (
          <div id="fileUpload">
            <Form.Control className="form-control-sm" id="file" type="file" onChange={handleGalleryChange} accept="image/*" multiple />
          </div>
        )}
        <div className="row justify-content-center align-items-center mt-3">
          {previewGallery.map((picture, index) => (
            <div className='col-md-auto' key={index}>
              <div className='thumbnail'>
                <img src={picture} alt={`preview-${index}`} className='img-fluid' />
                <span className="remove-icon" onClick={() => removeImage(index)}>
                  <FaTimes />
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleSaveNews} type="button" disabled={!picture || !title || !content}>
          Speichern
        </button>
        <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeNewsCreate} disabled={loading}>
          Verwerfen
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewsCreate;
