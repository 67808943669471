import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdOutlinePaid, MdCheckCircleOutline, MdArrowCircleUp } from "react-icons/md"; // MdOutlinePaid wieder hinzugefügt
import { HiOutlinePlus } from "react-icons/hi";

// Hilfsfunktion für Datumsformatierung
const formatGermanDate = (date) => {
    const d = dayjs(date);
    return d.isValid() ? d.locale('de').format('DD.MM.YYYY') : '-';
};

// Unterkomponente für eine einzelne Teilnehmerzeile
const ParticipantRow = ({ participant, tournamentCompleted, onShow, onUpdate, onDelete, isLast }) => {
    // Click Handlers
    const handlePaidClick = (e) => { e.stopPropagation(); onUpdate(participant, 1); }; // Topic 1 for Paid Toggle
    const handleAcceptClick = (e) => { e.stopPropagation(); onUpdate(participant, 3); }; // Topic 3 for Accept/MoveUp/Re-Register
    const handleRejectClick = (e) => { e.stopPropagation(); onUpdate(participant, 5); }; // Topic 5 for Reject/Unregister
    const handleDeleteClick = (e) => { e.stopPropagation(); onDelete(participant._id); }; // Direct Delete

    // Status Checks
    const isInternal = participant?.intern === true;
    const isPaid = participant?.paid === true; // Paid status wieder relevant

    const rowClasses = `row bg-light align-items-center py-2 border-top mx-0 ${isLast ? 'border-bottom' : ''}`;

    return (
        <div className={rowClasses}>
            {/* Teamname & Intern Indicator */}
            <div className="col-5 fs-7 text-start fw-semibold" style={{ cursor: 'pointer' }} onClick={() => onShow(participant)}>
                {participant.teamname}
                {isInternal && <span className="ms-1 text-muted fw-normal">(Intern)</span>}
            </div>
            {/* Date */}
            <div className="col-3 text-end text-wrap fs-7" style={{ cursor: 'pointer' }} onClick={() => onShow(participant)}>
                {formatGermanDate(participant.createdAt)}
            </div>
            {/* Action Icons */}
            {!tournamentCompleted && (
                <div className="col-4 text-end">
                    {isInternal ? (
                        // --- Icons for INTERNAL Teams ---
                        <>
                            {/* Zulassen/Akzeptieren Icon */}
                            {participant.status !== 1 && <MdCheckCircleOutline
                                size={23}
                                className='hover icon-edit mx-1 my-1'
                                onClick={handleAcceptClick} // Topic 3
                                title="Team zulassen/anmelden"
                                style={{ cursor: 'pointer' }}/> }
                            
                            {/* Löschen Icon */}
                            <RiDeleteBinLine
                                size={22}
                                className='hover icons mx-1 my-1' // Ggf. 'icons' zu 'icon-edit'
                                onClick={handleDeleteClick}
                                title="Internes Team löschen"
                                style={{ cursor: 'pointer' }}/>
                        </>
                    ) : (
                        // --- Icons for NON-INTERNAL Teams (Status-based) ---
                        <>
                            {/* Status 0/Null (Anfragen) */}
                            {!participant.status && (
                                <>
                                    {/* Paid Toggle hinzugefügt */}
                                    <MdOutlinePaid size={23} className={isPaid ? 'hover icon-edit mx-1 my-1 text-success' : 'hover icon-edit mx-1 my-1 text-danger'} onClick={handlePaidClick} title={isPaid ? "Bezahlt" : "Nicht bezahlt"} style={{ cursor: 'pointer' }}/>
                                    {isPaid ? ( // Nur wenn bezahlt -> Akzeptieren
                                        <MdCheckCircleOutline size={23} className='hover icon-edit mx-1 my-1' onClick={handleAcceptClick} title="Anfrage akzeptieren" style={{ cursor: 'pointer' }}/>
                                    ) : ( // Wenn nicht bezahlt -> Löschen
                                        <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={handleDeleteClick} title="Anfrage löschen" style={{ cursor: 'pointer' }}/>
                                    )}
                                </>
                            )}
                            {/* Status 1 (Teilnehmer) */}
                            {participant.status === 1 && (
                                 <IoMdCloseCircleOutline size={24} className='hover icon-edit mx-1 my-1' onClick={handleRejectClick} title="Teilnehmer abmelden" style={{ cursor: 'pointer' }}/>
                                 // Kein Paid-Icon hier
                            )}
                            {/* Status 2 (Warteliste) */}
                            {participant.status === 2 && (
                                <>
                                    <MdArrowCircleUp size={23} className='hover icon-edit mx-1 my-1' onClick={handleAcceptClick} title="Teilnehmer hochstufen" style={{ cursor: 'pointer' }}/>
                                    <IoMdCloseCircleOutline size={24} className='hover icon-edit mx-1 my-1' onClick={handleRejectClick} title="Teilnehmer abmelden" style={{ cursor: 'pointer' }}/>
                                </>
                            )}
                            {/* Status 3 (Abgemeldet) */}
                            {participant.status === 3 && (
                                <>
                                     {/* Paid Toggle hinzugefügt */}
                                     <MdOutlinePaid size={23} className={isPaid ? 'hover icon-edit mx-1 my-1 text-success' : 'hover icon-edit mx-1 my-1 text-danger'} onClick={handlePaidClick} title={isPaid ? "Bezahlt" : "Nicht bezahlt"} style={{ cursor: 'pointer' }}/>
                                    {isPaid ? ( // Nur wenn bezahlt -> Wiederanmelden
                                         <MdArrowCircleUp size={23} className='hover icon-edit mx-1 my-1' onClick={handleAcceptClick} title="Teilnehmer wieder anmelden" style={{ cursor: 'pointer' }}/>
                                     ) : ( // Wenn nicht bezahlt -> Löschen
                                         <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={handleDeleteClick} title="Teilnehmer endgültig löschen" style={{ cursor: 'pointer' }}/>
                                     )}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

// Hauptkomponente für Teilnehmerlisten
function ParticipantManager({
    tournament,
    inquiryList,
    confirmedList,
    waitList,
    canceledList,
    onShowParticipant,
    onUpdateParticipant, // Wird für Status UND Bezahlung verwendet (Topic 1, 3, 5)
    onDeleteParticipant,
    onAddParticipant,
    loading
}) {

    // Funktion zum Rendern einer Sektion (Titel + Liste)
    const renderListSection = (title, list, count = null, showTotal = false, total = 0) => {
        if (!list || list.length === 0) return null;

        return (
            <div className='mb-3'>
                {/* Header der Sektion */}
                <div className={`row mb-1 ms-1 align-items-center ${title === 'Teilnehmer' ? 'mt-3' : ''}`}>
                    <div className='col text-start rakete-font fs-5 text-uppercase fw-semibold'>{title}</div>
                    <div className='col text-end fw-semibold me-2'>
                         ( {count ?? list.length} {showTotal ? `/ ${total}` : ''} )
                    </div>
                </div>
                {/* Container für die Zeilen */}
                <div>
                    {list.map((participant, index) => (
                        <ParticipantRow
                            key={participant._id}
                            participant={participant}
                            tournamentCompleted={tournament.completed}
                            onShow={onShowParticipant}
                            onUpdate={onUpdateParticipant} // Übergibt Topic 1, 3 oder 5
                            onDelete={onDeleteParticipant}
                            isLast={index === list.length - 1}
                        />
                    ))}
                </div>
            </div>
        );
    };

    if (!tournament) {
        return null;
    }

    return (
        <>
             {!tournament.completed && (
                 <div className='text-end mb-2'>
                     <HiOutlinePlus
                         size={30}
                         className='mx-3 hover icon-edit'
                         onClick={onAddParticipant}
                         title="Teilnehmer hinzufügen"
                         style={{ cursor: 'pointer' }}
                     />
                 </div>
            )}

            {renderListSection("Anfragen", inquiryList)}
            {renderListSection("Teilnehmer", confirmedList, confirmedList.length, true, tournament.teams)}
            {renderListSection("Warteliste", waitList)}
            {renderListSection("Abmeldungen", canceledList)}
        </>
    );
}

export default ParticipantManager;
