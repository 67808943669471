import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { toast } from 'react-toastify';

// Importiere Swiper React Komponenten
import { Swiper, SwiperSlide } from 'swiper/react';

// Importiere Swiper Module (Navigation, Pagination etc.)
import { Navigation, Pagination, A11y } from 'swiper/modules';

// Importiere Swiper Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Importiere Icons und andere Komponenten
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineMail } from "react-icons/md";
import { FaList } from "react-icons/fa";
import TournamentAdd from './TournamentAdd';
import ParticipantEmails from './ParticipantEmails';
import EmailTemplates from './EmailTemplates';
import { IMAGE_URL } from '../../App.js'; // Pfad prüfen

function Tournament() {
  const [addTournament, setAddTournament] = useState(false);
  const [showEmails, setShowEmails] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/tournament')
      .then((response) => {
        const sortedTournaments = (response.data.data || [])
            .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());
        setTournaments(sortedTournaments);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Fehler beim Laden der Turniere.");
        setLoading(false);
      });
  }, []);

  const formatGermanDate = (date) => {
      const d = dayjs(date);
      return d.isValid() ? d.locale('de').format('DD.MM.YYYY HH:mm') : 'Datum ungültig';
  };

  const linkStyle = {
    textDecoration: "none",
    color: 'inherit'
  };

  // Hilfsfunktion zum Rendern einer einzelnen Karte
  const renderTournamentCard = (tournament) => {
      if (!tournament) return null;
      return (
           <Link to={`/tournament/${tournament._id}`} style={linkStyle}>
               <div className='my-2'>
                   <img
                       className="news-img rounded-top"
                       src={`${IMAGE_URL}/uploads/${tournament.picture}`}
                       alt={`${tournament.name}`}
                       onError={(e) => { e.target.onerror = null; e.target.src=`https://placehold.co/300x200/e1e1e1/909090?text=Bild+fehlt` }}
                   />
               </div>
               <div className="card-body p-2">
                 <p className="rakete-font rakete-rot text-center fs-5 mt-1 mb-2 fw-semibold underline0 card-title">{tournament.name}</p>
                 <p className="rakete-font fs-7 text-dark fw-semibold text-uppercase underline0 pb-1 text-center card-text">{formatGermanDate(tournament.date)}</p>
               </div>
           </Link>
      );
  }

  return (
    <>
       <style>{`
        /* Styling für Swiper Pfeile und Pagination */
        .swiper-button-prev, .swiper-button-next {
            color: #bb0505; /* Rakete-Rot */
            transform: scale(0.7);
        }
        .swiper-pagination-bullet-active {
            background-color: #bb0505 !important; /* Rakete-Rot */
        }
        /* Styling für die Slides und die Karte darin */
        .tournament-slide-card {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: stretch;
            box-sizing: border-box;
            padding: 0 5px; /* Kleiner Abstand zwischen Slides */
        }
        .tournament-card-content {
             width: 100%;
             max-width: 350px; /* Maximale Breite einer Karte */
             border: 1px solid rgba(0,0,0,.125);
             border-radius: 0.75rem; /* rounded-4 */
             margin: 0.5rem 0 !important; /* Vertikaler Abstand */
             background-color: white;
             display: flex;
             flex-direction: column;
             overflow: hidden;
        }
        .news-img {
             display: block;
             width: 100%;
             height: auto;
        }
       `}</style>

      <div className='row d-flex align-items-center justify-content-center pt-4'>
        <div className='fw-semibold headline rakete-font fs-1 mt-3 text-center text-uppercase mb-3'>TURNIERE</div>
      </div>

      {/* Container für Swiper */}
      <div className="mx-3 bg-light shadow-lg rounded-top-4 py-4">
        {loading ? (
             <div className="col-12 text-center text-muted py-5">Lade Turniere...</div>
        ) : tournaments.length === 0 ? (
            <div className="col-12 text-center text-muted py-5">Keine Turniere gefunden.</div>
        ) : (
            <Swiper
                // Installierte Module
                modules={[Navigation, Pagination, A11y]}
                // Grundlegende Einstellungen
                spaceBetween={10} // Wird durch Breakpoints überschrieben
                slidesPerView={1} // Mobil: 1 Slide
                navigation // Pfeile aktivieren
                pagination={{ clickable: true }} // Punkte aktivieren
                loop={tournaments.length > 3} // Loop nur bei genügend Slides
                // centeredSlides={true} // Entfernt: Zentriert den aktiven Slide
                centerInsufficientSlides={true} // Beibehalten: Zentriert, wenn weniger Slides als slidesPerView
                // Responsives Verhalten
                breakpoints={{
                    // Ab 768px (md)
                    768: {
                      slidesPerView: 3, // Desktop: 3 Slides
                      slidesPerGroup: 1, // Scrolle immer nur 1 weiter
                      spaceBetween: 20
                    },
                }}
                className="mySwiper"
            >
                {tournaments.map((tournament) => (
                    <SwiperSlide key={tournament._id} className="tournament-slide-card">
                         {/* Container für das Kartenstyling */}
                         <div className="tournament-card-content card news-card">
                            {renderTournamentCard(tournament)}
                         </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )}
      </div>

      {/* Untere Aktionsleiste */}
      <div className='rounded-bottom-4 bg-white mx-3 pt-3 shadow-lg'>
        <div className='text-center'>
          <HiOutlinePlus size={32} className='mb-3 mx-3 hover icon-edit' onClick={() => setAddTournament(true)} title="neues Turnier"/>
          <FaList size={26} className='mb-3 mx-3 hover icon-edit' onClick={() => setShowEmails(true)} title="Emailverteiler" />
          <MdOutlineMail size={32} className='mb-3 mx-3 hover icon-edit' onClick={() => setShowEmailTemplate(true)} title="Emails bearbeiten"/>
        </div>
      </div>

      {/* Modals */}
      <ParticipantEmails show={showEmails} onHide={() => setShowEmails(false)} />
      <TournamentAdd show={addTournament} onHide={() => setAddTournament(false)} />
      <EmailTemplates show={showEmailTemplate} onHide={() => setShowEmailTemplate(false)} />
    </>
  )
}

export default Tournament;
