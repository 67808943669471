import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";

function TournamentHeader({ name }) {
    return (
        <div className='row align-items-center'>
            <div className="col-2">
                <Link to={"/events"}>
                    <IoMdArrowRoundBack size={40} className="back-button" />
                </Link>
            </div>
            <div className='col-8'>
                <p className='rakete-font fw-bold mt-4 fs-1 text-center text-uppercase headline'>
                    {name || 'Turnierdetails'}
                </p>
            </div>
            <div className="col-2"></div>
        </div>
    );
}

export default TournamentHeader;
