import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { FaXmark } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import { toast } from "react-toastify";

function ParticipantEmails({ show, onHide }) {
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]); // Ursprüngliche, unveränderte Liste
  const [editEmails, setEditEmails] = useState([]); // Bearbeitbare Liste mit allen Einträgen
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992); // Bildschirmbreite prüfen

  // Emails vom Server laden
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/ParticipantContact`)
      .then((response) => {
        setLoading(false);
        setEmails(response.data.data);
        setEditEmails(response.data.data); // Initial die bearbeitbare Liste synchronisieren
      })
      .catch((error) => {
        setLoading(false);
        alert("An error occurred. Please check Console");
        console.log(error);
      });
  }, []);

  // Bildschirmgröße überwachen
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Eintrag aus der Liste löschen (sowohl bestehende als auch neue)
  const handleDeleteEmail = (emailId) => {
    setEditEmails(editEmails.filter(email => email._id !== emailId));
  };

  // Neue Eingabezeile hinzufügen
  const handleAddNewRow = () => {
    const newContact = {
      _id: `temp-${Date.now()}`, // Temporäre ID
      email: '',
      firstname: '',
      lastname: '',
    };
    setEditEmails([...editEmails, newContact]);
  };

  // Eingabefelder ändern und automatisch in die Liste übernehmen
  const handleInputChange = (id, field, value) => {
    const updatedEmails = editEmails.map(email => {
      if (email._id === id) {
        return { ...email, [field]: value };
      }
      return email;
    });
    setEditEmails(updatedEmails);
  };

  // Gesamte Liste an den Server senden und speichern
  const handleSave = async () => {
    try {
      const response = await axios.put(`/tournament/emails/updateAll`, {
        contacts: editEmails.filter(email => email.email), // Nur Einträge mit Email senden
      });
      setEmails(response.data.data); // Aktualisierte Liste vom Server übernehmen
      setEditEmails(response.data.data); // Bearbeitbare Liste synchronisieren
      toast.success("Emailverteiler erfolgreich gespeichert");
    } catch (error) {
      toast.error("Fehler beim Speichern:", error);
      alert("Fehler beim Speichern des Emailverteilers");
    }
  };

  // Alles auf ursprünglichen Zustand zurücksetzen
  const handleReset = () => {
    setEditEmails(emails); // Zurück auf die ursprünglichen Daten
    onHide(); // Modal schließen
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} centered className='no-carot'>
      <Modal.Header className='justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>Emailverteiler ({emails.length})</Modal.Title>
      </Modal.Header>
      <Modal.Body className='shadow'>
        <div className='mx-2'>
          <p className="fs-7 mt-2 font-dark text-justify">Die Emailliste dient dazu, bei der Erstellung eines neuen Turniers automatisch eine Infomail an alle unten aufgeführten E-Mail-Adressen zu versenden. Neue Anmeldungen werden direkt erfasst und die Liste wird entsprechend aktualisiert.</p>
          <div className='fw-semibold text-center fs-6'>
            <div className="text-center">
              {/* Kopfzeile */}
              {!isSmallScreen ? (
                <div className='row py-2 d-flex border-top border-start border-end rounded-top-4 align-items-center justify-content-center'>
                  <div className='col-4 fs-7 text-start text-wrap fw-semibold'>Email</div>
                  <div className='col-3 fs-7 text-start text-wrap text-secondary'>Vorname</div>
                  <div className='col-3 fs-7 text-start text-wrap text-secondary'>Nachname</div>
                  <div className='col-2'></div>
                </div>
              ) : (
                <div className='row py-2 d-flex border-top border-start border-end rounded-top-4 align-items-center justify-content-center'>
                  <div className='col-11 fs-7 text-start text-wrap fw-semibold'>Email</div>
                  <div className='col-1'></div>
                </div>
              )}
              {/* Liste */}
              {editEmails.length === 0 ? (
                                <div className='row py-3 d-flex bg-light text-secondary border-top border-start border-end align-items-center justify-content-center'>
                                Keine Emails in der Liste
                              </div>              
              ) : (
                editEmails.map((email) => (
                  <div key={email._id} className="row py-3 d-flex bg-light border-top border-start border-end align-items-center justify-content-center">
                    {!isSmallScreen ? (
                      <>
                        <div className='col-4 fs-7 text-start text-wrap fw-semibold'>
                          {email._id.startsWith('temp-') ? (
                            <input
                              type="email"
                              value={email.email}
                              onChange={(e) => handleInputChange(email._id, 'email', e.target.value)}
                              placeholder="Email"
                              className="form-control form-control-sm"
                            />
                          ) : (
                            email.email
                          )}
                        </div>
                        <div className='col-3 fs-7 text-start text-wrap text-secondary'>
                          {email._id.startsWith('temp-') ? (
                            <input
                              type="text"
                              value={email.firstname}
                              onChange={(e) => handleInputChange(email._id, 'firstname', e.target.value)}
                              placeholder="Vorname"
                              className="form-control form-control-sm"
                            />
                          ) : (
                            email.firstname ? email.firstname : '-'
                          )}
                        </div>
                        <div className='col-3 fs-7 text-start text-wrap text-secondary'>
                          {email._id.startsWith('temp-') ? (
                            <input
                              type="text"
                              value={email.lastname}
                              onChange={(e) => handleInputChange(email._id, 'lastname', e.target.value)}
                              placeholder="Nachname"
                              className="form-control form-control-sm"
                            />
                          ) : (
                            email.lastname ? email.lastname : '-'
                          )}
                        </div>
                        <div className='col-2'>
                          <FaXmark
                            size={20}
                            className='hover icon-edit'
                            onClick={() => handleDeleteEmail(email._id)}
                            title="Email löschen"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='col-10 fs-7 text-start text-wrap fw-semibold'>
                          {email._id.startsWith('temp-') ? (
                            <>
                              <input
                                type="email"
                                value={email.email}
                                onChange={(e) => handleInputChange(email._id, 'email', e.target.value)}
                                placeholder="Email"
                                className="form-control form-control-sm mb-2"
                              />
                              <div className="row">
                                <div className="col-6">
                                  <input
                                    type="text"
                                    value={email.firstname}
                                    onChange={(e) => handleInputChange(email._id, 'firstname', e.target.value)}
                                    placeholder="Vorname"
                                    className="form-control form-control-sm"
                                  />
                                </div>
                                <div className="col-6">
                                  <input
                                    type="text"
                                    value={email.lastname}
                                    onChange={(e) => handleInputChange(email._id, 'lastname', e.target.value)}
                                    placeholder="Nachname"
                                    className="form-control form-control-sm"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {email.email}
                                <div className="col-6 fs-7 text-secondary">{email.firstname ? email.firstname : '-'} {email.lastname ? email.lastname : '-'}</div>
                            </>
                          )}
                        </div>
                        <div className='col-2'>
                          <FaXmark
                            size={20}
                            className='hover icon-edit'
                            onClick={() => handleDeleteEmail(email._id)}
                            title="Email löschen"
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))
              )}
              <div
                className='row py-2 d-flex border rounded-bottom-4 hover add align-items-center justify-content-center'
                onClick={handleAddNewRow}
              >
                <HiOutlinePlus size={22} className='' title="Email hinzufügen" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
      <Button variant="secondary" onClick={handleReset}>
          Verwerfen
        </Button>
        <Button variant="danger" onClick={handleSave}>
          Speichern
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
}

export default ParticipantEmails;