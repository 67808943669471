import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HiOutlinePlus } from "react-icons/hi";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import NewsPreview from '../components/news/newsPreview';
import NewsDelete from '../components/news/newsDelete';
import NewsCreate from '../components/news/newsCreate';
import NewsDetail from '../components/news/newsDetail';
import NewsEdit from '../components/news/newsEdit';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IMAGE_URL } from '../App';

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [showNews, setShowNews] = useState(false);
  const [createNews, setCreateNews] = useState(false);
  const [deleteNews, setdeleteNews] = useState(false);
  const [selectedNews, setSelectedNews] = useState(0);
  const [editNews, setEditNews] = useState(false);

  const handleNewsCreate = () => {
    setCreateNews(true);
  };

  const handleNewsEdit = (id) => {
    setEditNews(true);
    setSelectedNewsId(id);
  }

  const handleNewsDetail = (id) => {
    setShowNews(true);
    setSelectedNewsId(id);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get('/api/News')
      .then((response) => {
        const sortedNews = response.data.data.sort((a, b) => a.index - b.index);
        setNews(sortedNews);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleNewsPreviewClick = (index) => {
    setSelectedNews(index);
  };

  const handleHide = (refresh) => {
    setEditNews(false);
    setCreateNews(false);
    setShowNews(false);
    setdeleteNews(false);
    if (refresh === true) {
      axios
        .get('/api/News')
        .then((response) => {
          const sortedNews = response.data.data.sort((a, b) => a.index - b.index);
          setNews(sortedNews);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const editIndex = (id, index, direction) => {
    const formData = new FormData();
    if (direction === 0) { 
    formData.append('index', index + 1);
  }
  if (direction === 1) {
    formData.append('index', index - 1);
  }

  setLoading(true);

  axios
    .put(`/api/News/${id}`, formData)
    .then(() => {
      axios
        .get('/api/News')
        .then((response) => {
          const sortedNews = response.data.data.sort((a, b) => a.index - b.index);
          setNews(sortedNews);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });

    })
    .catch((error) => {
      setLoading(false);
      alert("An error occurred. Please check Console");
    });
}

return (
  <div className='container-fluid'>
    <p className='fw-semibold rakete-font headline headline fs-1 mt-3 text-center'>NEWS</p>
    <div className='row'>
      <div className='col-lg'>
        <NewsPreview newsData={news} newsIndex={selectedNews} onNewsClick={handleNewsDetail} />
      </div>
      <div className='col-lg-6'>
        <div className="px-3 bg-white mt-3 py-3 rounded-4 shadow-lg">
          <div className='row d-flex align-items-center '>
            <div className='col-9'><p className='fw-semibold rakete-font text-danger fs-3 ms-3 text-start'>NEWSLINES</p></div>
            <div className='col-3 text-end'><HiOutlinePlus size={28} className='mx-4 hover icon-edit' onClick={() => handleNewsCreate()} /></div>
          </div>
          <div className=' fs-7'>
            <div className="text-center">
              {news.map((newsItem, index) => (
                <div key={newsItem._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center" onClick={() => handleNewsPreviewClick(index)}>
                  <div className='col-2 rakete-font'>
                    <div className={newsItem.index === 1 ? "text-white mb-2" : "hover icons mb-2"} onClick={() => editIndex(newsItem._id, newsItem.index, 1)}><IoIosArrowUp size={25} /></div>
                    <div className='fs-6'>{newsItem.index}</div>
                    <div className={news.length === newsItem.index ? "text-white mt-2" : "hover icons mt-2"}><IoIosArrowDown size={25} onClick={() => editIndex(newsItem._id, newsItem.index, 0)}/></div>
                  </div>
                  <div className="col-8">
                    <div className='row d-flex align-items-center justify-content-center'>
                      <div className='col-md-6 my-2'><img className="news-thumb rounded" src={`${IMAGE_URL}/uploads/${newsItem.picture}`} alt={`${newsItem.title}`} /></div>
                      <div className='col-md-6 rakete-font text-center'>{newsItem.title}</div>
                    </div>
                  </div>
                  <div className='col-2'>
                    <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleNewsEdit(newsItem._id)} />
                    <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => setdeleteNews(newsItem._id)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <NewsCreate show={createNews} onHide={handleHide} />
      {deleteNews && <NewsDelete show={deleteNews} onHide={handleHide} id={deleteNews} />}
      <NewsDetail
        show={showNews}
        onHide={handleHide}
        news={news.find(item => item._id === selectedNewsId)}
      />
      <NewsEdit show={editNews} onHide={handleHide} news={news.find(item => item._id === selectedNewsId)} id={selectedNewsId} />
    </div>
  </div>
)
}

export default News;
