import React from 'react';
import { FaCirclePlay, FaCircleStop, FaRegCalendarCheck } from "react-icons/fa6";
import { TbEditCircle } from "react-icons/tb";
import { FiDownloadCloud, FiMail } from "react-icons/fi";

function TournamentActions({
    tournament,
    onPublish,
    onClose,
    onEdit,
    onDownload,
    onSendEmail,
    onToggleRegistration
}) {
    // Zeige keine Aktionen, wenn das Turnier abgeschlossen ist
    if (tournament.completed) {
        return null;
    }

    return (
        <div className='py-3 border-top d-flex justify-content-center align-items-center'>
            {/* Veröffentlichen / Beenden Button */}
            {!tournament.published ? (
                <FaCirclePlay
                    size={30}
                    className='mx-3 icon-edit text-danger'
                    onClick={onPublish}
                    title='Turnier veröffentlichen & Einladungen senden'
                    style={{ cursor: 'pointer' }}
                />
            ) : (
                <FaCircleStop
                    size={30}
                    className='mx-3 icon-edit text-danger'
                    onClick={onClose}
                    title='Turnier beenden & Platzierung eintragen'
                    style={{ cursor: 'pointer' }}
                />
            )}

            {/* Bearbeiten Button */}
            <TbEditCircle
                size={25}
                className='icon-edit mx-3'
                onClick={onEdit}
                title="Turnier bearbeiten"
                style={{ cursor: 'pointer' }}
            />

            {/* Download Button */}
            <FiDownloadCloud
                size={25}
                className='icon-edit mx-3'
                title="Teilnehmerliste herunterladen"
                onClick={onDownload}
                style={{ cursor: 'pointer' }}
            />

            {/* E-Mail Senden Button */}
            <FiMail
                size={25}
                className='icon-edit mx-4'
                onClick={onSendEmail}
                title="Nachricht senden"
                style={{ cursor: 'pointer' }}
            />

            {/* Anmeldestatus Button */}
            <FaRegCalendarCheck
                size={28}
                className={!tournament.registration ? 'icon-edit mx-3 text-danger' : "icon-edit mx-3 text-success"}
                onClick={onToggleRegistration}
                title={!tournament.registration ? "Anmeldung geschlossen" : "Anmeldung offen"}
                style={{ cursor: 'pointer' }}
            />
        </div>
    );
}

export default TournamentActions;
