import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import dayjs from 'dayjs';

// Initialer Zustand für das Formular
const initialFormData = {
    teamname: '', firstname: '', lastname: '', birthday: '',
    street: '', houseNumber: '', postalCode: '', city: '',
    email: '', email1: '', phoneNumber: '',
    confirmRules: false, status: 0,
    intern: false,
    paid: false // Paid initial auf false setzen
};

function PartificantAdd({ show, onHide, tournamentid, refresh }) {
    const [formData, setFormData] = useState(initialFormData);
    const [streetAndNumberInput, setStreetAndNumberInput] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!show) {
            setFormData(initialFormData);
            setStreetAndNumberInput('');
        } else {
             setFormData(initialFormData);
             setStreetAndNumberInput('');
        }
    }, [show]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleStreetNumberChange = (e) => {
        setStreetAndNumberInput(e.target.value);
    };

     const handleInternalChange = (e) => {
        const isChecked = e.target.checked;
        setFormData(prev => ({
            ...prev,
            intern: isChecked,
            // Setze paid auf true, wenn intern ausgewählt wird
            paid: isChecked ? true : prev.paid // Behält paid bei, wenn intern abgewählt wird
        }));
        if (isChecked) {
             setStreetAndNumberInput('');
        } else {
             setStreetAndNumberInput(`${formData.street || ''} ${formData.houseNumber || ''}`.trim());
        }
    };

    const parseStreetAndNumber = (combined) => {
        const trimmedCombined = combined.trim();
        const match = trimmedCombined.match(/^(.*?)\s+([\d]+[a-zA-Z]*)$/);
        if (match) {
            return { street: match[1].trim(), houseNumber: match[2].trim() };
        }
        return { street: trimmedCombined, houseNumber: '' };
    };

    const handleAddParticipant = () => {
        let dataToSend = { ...formData };
        let validationOk = true;
        let missingFields = [];

        if (!dataToSend.intern) {
            const { street, houseNumber } = parseStreetAndNumber(streetAndNumberInput);
            dataToSend.street = street;
            dataToSend.houseNumber = houseNumber;

            const requiredFields = {
                teamname: "Teamname", firstname: "Vorname", lastname: "Nachname",
                birthday: "Geburtstag", street: "Straße", /* houseNumber: "Hausnummer", */
                postalCode: "PLZ", city: "Ort", email: "Email",
                phoneNumber: "Telefon"
            };

            // Prüfung auf leere Pflichtfelder
            for (const field in requiredFields) {
                 if (!dataToSend[field] || (typeof dataToSend[field] === 'string' && dataToSend[field].trim() === '')) {
                    validationOk = false;
                    missingFields.push(requiredFields[field]);
                }
            }
             // Optional: Hausnummer als Pflichtfeld?
            // if (!houseNumber || houseNumber.trim() === '') {
            //      validationOk = false;
            //      missingFields.push("Hausnummer");
            // }

            if (!dataToSend.confirmRules) {
                 validationOk = false;
            }

            if (!validationOk) {
                 let errorMsg = "Bitte füllen Sie die Pflichtfelder aus.";
                 if (missingFields.length > 0) {
                     errorMsg = `Pflichtfelder fehlen: ${missingFields.join(', ')}`;
                 }
                 if (!dataToSend.confirmRules && !missingFields.includes("Zustimmung Regeln/Datenschutz")) {
                     errorMsg += (missingFields.length > 0 ? " und bestätigen Sie die Regeln/Datenschutz." : "Bitte bestätigen Sie Regeln/Datenschutz.");
                 }
                 toast.error(errorMsg);
                 return;
             }

             if (dataToSend.birthday && dayjs(dataToSend.birthday).isValid()) {
                  dataToSend.birthday = dayjs(dataToSend.birthday).toISOString();
             } else {
                  toast.error("Bitte geben Sie ein gültiges Geburtsdatum ein.");
                  return;
             }
             // Sicherstellen, dass paid false ist, wenn nicht intern (falls es vorher durch Umschalten true war)
             dataToSend.paid = false;

        } else {
             // Wenn intern, nur Teamname prüfen
             if (!dataToSend.teamname || dataToSend.teamname.trim() === '') {
                 toast.error("Bitte geben Sie einen Teamnamen an.");
                 return;
             }
             // Payload für internen Teilnehmer vorbereiten
             dataToSend = {
                 teamname: dataToSend.teamname.trim(),
                 intern: true,
                 paid: true, // Automatisch bezahlt setzen
                 status: dataToSend.status // Status beibehalten
             };
        }

        setLoading(true);
        axios.post(`/tournament/${tournamentid}/participants`, dataToSend)
             .then(() => {
                 setLoading(false);
                 toast.success("Teilnehmer erfolgreich hinzugefügt.");
                 onHide();
                 refresh();
             })
             .catch((error) => {
                 setLoading(false);
                 toast.error(error.response?.data?.message || "Fehler beim Hinzufügen des Teilnehmers.");
                 console.error("Fehler:", error);
             });
    };

    // Helper zum Rendern einer Zeile (Label + Input)
    const renderInputRow = (label, name, type = "text", required = false, disabled = false, children = null) => (
        <div className={`row bg-light align-items-center py-2 border-top`}>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">
                {label}{required && !formData.intern && <span className="text-danger">*</span>}
            </div>
            <div className="col text-start">
                {children ? children : (
                    <Form.Control
                        size="sm"
                        type={type}
                        name={name}
                        // Wert aus formData oder leerer String
                        value={formData[name] ?? ''}
                        onChange={handleInputChange}
                        required={required && !formData.intern}
                        disabled={disabled}
                        placeholder={label}
                    />
                )}
            </div>
        </div>
    );

    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5 border-0' closeButton>
                Teilnehmer hinzufügen
            </Modal.Header>
            <Modal.Body className='fs-7 pt-0'>

                {/* Intern Switch */}
                 <div className='row bg-light align-items-center py-2 border-top'>
                     <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Intern</div>
                     <div className="col text-start">
                        <Form.Check
                            type="switch"
                            id="intern-switch"
                            name="intern"
                            checked={formData.intern}
                            onChange={handleInternalChange}
                            label={formData.intern ? "Ja" : "Nein"}
                        />
                     </div>
                 </div>

                {/* Teamname (immer aktiv) */}
                {renderInputRow("Teamname", "teamname", "text", true, false)}

                {/* Andere Felder (deaktiviert wenn intern) */}
                {renderInputRow("Vorname", "firstname", "text", true, formData.intern)}
                {renderInputRow("Nachname", "lastname", "text", true, formData.intern)}
                {renderInputRow("Geburtstag", "birthday", "date", true, formData.intern)}

                {/* Straße & Nr. */}
                 <div className={`row bg-light align-items-center py-2 border-top`}>
                     <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">
                         Straße & Nr.{!formData.intern && <span className="text-danger">*</span>}
                     </div>
                     <div className="col text-start">
                         <Form.Control
                             size="sm"
                             type="text"
                             name="streetAndNumberInput"
                             value={streetAndNumberInput}
                             onChange={handleStreetNumberChange}
                             required={!formData.intern}
                             disabled={formData.intern}
                             placeholder="Straße und Hausnummer"
                         />
                     </div>
                 </div>

                 {/* PLZ / Ort */}
                 <div className={`row bg-light align-items-center py-2 border-top`}>
                     <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">
                         PLZ / Ort{!formData.intern && <span className="text-danger">*</span>}
                     </div>
                     <div className="col text-start">
                         <Row className="g-2">
                             <Col xs={4}>
                                 <Form.Control
                                     size="sm" type="text" name="postalCode"
                                     value={formData.postalCode} onChange={handleInputChange}
                                     required={!formData.intern} disabled={formData.intern} placeholder="PLZ"
                                 />
                             </Col>
                             <Col xs={8}>
                                 <Form.Control
                                     size="sm" type="text" name="city"
                                     value={formData.city} onChange={handleInputChange}
                                     required={!formData.intern} disabled={formData.intern} placeholder="Stadt"
                                 />
                             </Col>
                         </Row>
                     </div>
                 </div>

                {renderInputRow("Email", "email", "email", true, formData.intern)}
                {renderInputRow("Email 2", "email1", "email", false, formData.intern)}
                {renderInputRow("Telefon", "phoneNumber", "tel", true, formData.intern)}

                {/* Zustimmung (nur relevant wenn nicht intern) */}
                 <div className={`row bg-light align-items-center py-3 border-top border-bottom`}>
                    <div className="col-10 offset-1 text-start">
                         <Form.Check
                             type="checkbox"
                             id="confirmRules"
                             name="confirmRules"
                             checked={formData.confirmRules}
                             onChange={handleInputChange}
                             disabled={formData.intern}
                             required={!formData.intern}
                             label={<>Team hat Regeln & Datenschutz zugestimmt {!formData.intern && <span className="text-danger">*</span>}</>}
                             className="fw-semibold"
                         />
                     </div>
                 </div>

            </Modal.Body >
            <Modal.Footer className='border-0'>
                 <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" type="button" onClick={onHide} disabled={loading}>
                     <FiXCircle className="me-1"/> Verwerfen
                 </button>
                 <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" type="button" onClick={handleAddParticipant} disabled={loading}>
                     {loading ? 'SPEICHERT...' : <><FiPlusCircle className="me-1"/> Hinzufügen</>}
                 </button>
            </Modal.Footer>
        </Modal >
    );
};

export default PartificantAdd;
