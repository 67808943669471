import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
// Button von react-bootstrap wird nicht mehr benötigt
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import axios from 'axios';
import { FiCheckCircle, FiXCircle } from "react-icons/fi"; // Icons importieren

function TournamentClose({ show, onHide, participants = [], tournamentId, refresh }) { // Default für participants
    // State für das Ranking-Array, initial leer
    const [ranking, setRanking] = useState([]);
    const [loading, setLoading] = useState(false);

    // Initialisiere oder resette das Ranking-Array, wenn das Modal gezeigt wird oder sich Teilnehmer ändern
    useEffect(() => {
        if (show && participants.length > 0) {
            // Erstelle ein Array mit leer Strings, Länge entspricht Teilnehmerzahl
            setRanking(Array(participants.length).fill(''));
        } else if (!show) {
            setRanking([]); // Leere das Array, wenn Modal geschlossen wird
        }
    }, [show, participants]);

    // Handler zum Ändern eines Rangs im State
    const handleRankingChange = (index, value) => {
        setRanking(currentRanking => {
            const newRanking = [...currentRanking];
            newRanking[index] = value;
            return newRanking;
        });
    };

    // Funktion zum Schließen und Zurücksetzen
    const handleClose = () => {
        setRanking(Array(participants.length).fill('')); // Reset state on close
        onHide();
    };

    // Funktion zum Beenden des Turniers
    const handleEndTournament = async () => {
        // Validierung: Prüfen, ob für jeden Platz ein Team gewählt wurde
        const allRanksSelected = ranking.every(rank => rank && rank !== '');
        if (!allRanksSelected || ranking.length !== participants.length) {
            toast.error("Bitte weisen Sie allen Plätzen ein Team zu!");
            return;
        }
        // Validierung: Prüfen, ob alle ausgewählten Teams einzigartig sind (sollte durch UI verhindert werden, aber sicher ist sicher)
        const uniqueTeams = new Set(ranking);
        if (uniqueTeams.size !== ranking.length) {
             toast.error("Ein Team wurde mehrfach ausgewählt. Bitte korrigieren Sie die Eingabe.");
             return;
        }


        setLoading(true);
        try {
            // Das 'ranking'-State Array enthält bereits die korrekten Daten
            const payload = { ranking: ranking };
            const response = await axios.put(`/tournament/close/${tournamentId}`, payload);

            // Status 200 oder anderer Erfolgsstatus
            if (response.status >= 200 && response.status < 300) {
                toast.success("Turnier erfolgreich beendet!");
                if(refresh) refresh(); // Parent Komponente refreshen
                handleClose(); // Modal schließen und Reset
            } else {
                 throw new Error(response.data?.message || "Unbekannter Fehler");
            }
        } catch (error) {
            console.error("Fehler beim Beenden des Turniers:", error);
            toast.error(error.response?.data?.message || "Fehler beim Beenden des Turniers!");
        } finally {
            setLoading(false);
        }
    };

    return (
        // handleClose für onHide verwenden, um State zu resetten
        <Modal size="md" show={show} onHide={handleClose} centered className='no-carot'>
            <Modal.Header className='justify-content-center border border-0 rakete-rot' closeButton>
                <Modal.Title className='rakete-font fw-semibold text-uppercase'>Turnier beenden & Ranking</Modal.Title>
            </Modal.Header>
            <Modal.Body className='shadow'>
                <div className='mx-2'>
                    <p className="text-center mb-3">Bitte weisen Sie allen teilnehmenden Teams eine Platzierung zu.</p>
                    <Form>
                        {/* Generiere dynamisch ein Dropdown für jeden Rang */}
                        {ranking.map((selectedTeamName, index) => {
                            const rank = index + 1;

                            // Filtert Teams heraus, die bereits einem ANDEREN Rang zugewiesen wurden
                            const availableOptions = participants.filter(p =>
                                // Entweder das aktuell für diesen Rang ausgewählte Team...
                                p.teamname === selectedTeamName ||
                                // ...oder ein Team, das in keinem ANDEREN Rang ausgewählt ist
                                !ranking.some((r, rIndex) => rIndex !== index && r === p.teamname)
                            );

                            return (
                                <Form.Group className="my-2" key={rank}>
                                    <Form.Label className="fw-semibold">{rank}. Platz</Form.Label>
                                    <Form.Select
                                        value={selectedTeamName}
                                        onChange={(e) => handleRankingChange(index, e.target.value)}
                                        className="form-control-sm"
                                        required // HTML5 Validierung
                                    >
                                        <option value="">Team auswählen...</option>
                                        {/* Zeige nur verfügbare Optionen an */}
                                        {availableOptions.map(p => (
                                            <option key={p._id} value={p.teamname}>
                                                {p.teamname || 'Unbekannt'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            );
                        })}
                    </Form>
                </div>
            </Modal.Body>
            {/* Angepasster Footer */}
            <Modal.Footer className="border-0">
                 {/* Abbrechen Button (Grau) */}
                 <button
                     className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                     type="button"
                     onClick={handleClose}
                     disabled={loading}
                 >
                      <FiXCircle className="me-1"/> Abbrechen
                 </button>
                 {/* Beenden Button (Rot) */}
                 <button
                     className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                     type="button"
                     onClick={handleEndTournament}
                     disabled={loading}
                 >
                     {loading ? 'BEENDET...' : <><FiCheckCircle className="me-1"/> Turnier beenden</>}
                 </button>
            </Modal.Footer>
        </Modal>
    );
}

export default TournamentClose;
