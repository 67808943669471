import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


function MessageDetail({ show, onHide, message }) {

  if (!message) {
    return null;
  }



  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return new Date(date).toLocaleDateString('de-DE', options);
  };


  return (
    <Modal size="md" show={show} onHide={onHide()} centered className='no-carot'>
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>{message.topic === 1 ? "Mitgliedsanfrage" : message.topic === 2 ? "Sponsoring" : message.topic === 0 ? "Anfrage" : "Turnieranmeldung"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light shadow'>
        <div className='mx-2'>
          <p className="fs-7 fw-semibold text-end mt-2">{formattedDate(message.createdAt)}</p>
          <div className='fw-semibold text-center fs-6'>
            {message.firstname} {message.lastname}{" "}
            {message.topic === 0
              ? "hat eine Frage"
              : message.topic === 1
                  ? `möchte Rakete ${message.team === 1 ? 'Erste' : message.team === 2 ? 'Zweite' : message.team === 4 ? 'Mixed' : 'Frauen'} beitreten` 
                : message.topic === 2
                  ? "möchte uns unterstützen"
                  : "hat ein Team angemeldet"}
          </div>
          <div className='text-center fs-7'>{message.email}</div>
          <div className='py-4 px-2' dangerouslySetInnerHTML={{ __html: message.message }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='text-center py-1'>
          <Link to={`mailto:${message.email}`}><button type="button" className="btn btn-danger text-center" title={message.email}>Antworten</button></Link>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MessageDetail;